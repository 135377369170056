const actionTypes = {
  FETCH_TOKEN_REQUEST: 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
  FETCH_TOKEN_FAILURE: 'FETCH_TOKEN_FAILURE',

  VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',

  LIST_PACKS_CLEAR: 'LIST_PACKS_CLEAR',
  LIST_PACKS_REQUEST: 'LIST_PACKS_REQUEST',
  LIST_PACKS_SUCCESS: 'LIST_PACKS_SUCCESS',
  LIST_PACKS_FAILURE: 'LIST_PACKS_FAILURE',

  LIST_SUMMARY_PACKS_REQUEST: 'LIST_SUMMARY_PACKS_REQUEST',
  LIST_SUMMARY_PACKS_SUCCESS: 'LIST_SUMMARY_PACKS_SUCCESS',
  LIST_SUMMARY_PACKS_FAILURE: 'LIST_SUMMARY_PACKS_FAILURE',

  CREATE_PACK_REQUEST: 'CREATE_PACK_REQUEST',
  CREATE_PACK_SUCCESS: 'CREATE_PACK_SUCCESS',
  CREATE_PACK_FAILURE: 'CREATE_PACK_FAILURE',

  DUPLICATE_PACK_REQUEST: 'DUPLICATE_PACK_REQUEST',
  DUPLICATE_PACK_SUCCESS: 'DUPLICATE_PACK_SUCCESS',
  DUPLICATE_PACK_FAILURE: 'DUPLICATE_PACK_FAILURE',

  LIST_RECIPES_CLEAR: 'LIST_RECIPES_CLEAR',
  LIST_RECIPES_REQUEST: 'LIST_RECIPES_REQUEST',
  LIST_RECIPES_SUCCESS: 'LIST_RECIPES_SUCCESS',
  LIST_RECIPES_FAILURE: 'LIST_RECIPES_FAILURE',

  LIST_UNITS_REQUEST: 'LIST_UNITS_REQUEST',
  LIST_UNITS_SUCCESS: 'LIST_UNITS_SUCCESS',
  LIST_UNITS_FAILURE: 'LIST_UNITS_FAILURE',

  RETRIEVE_RECIPE_REQUEST: 'RETRIEVE_RECIPE_REQUEST',
  RETRIEVE_RECIPE_SUCCESS: 'RETRIEVE_RECIPE_SUCCESS',
  RETRIEVE_RECIPE_FAILURE: 'RETRIEVE_RECIPE_FAILURE',

  RETRIEVE_PACK_REQUEST: 'RETRIEVE_PACK_REQUEST',
  RETRIEVE_PACK_SUCCESS: 'RETRIEVE_PACK_SUCCESS',
  RETRIEVE_PACK_FAILURE: 'RETRIEVE_PACK_FAILURE',

  LIST_CUISINE_TAGS_REQUEST: 'LIST_CUISINE_TAGS_REQUEST',
  LIST_CUISINE_TAGS_SUCCESS: 'LIST_CUISINE_TAGS_SUCCESS',
  LIST_CUISINE_TAGS_FAILURE: 'LIST_CUISINE_TAGS_FAILURE',

  LIST_PACK_THEMES_REQUEST: 'LIST_PACK_THEMES_REQUEST',
  LIST_PACK_THEMES_SUCCESS: 'LIST_PACK_THEMES_SUCCESS',
  LIST_PACK_THEMES_FAILURE: 'LIST_PACK_THEMES_FAILURE',

  LIST_ATTRIBUTE_TAGS_REQUEST: 'LIST_ATTRIBUTE_TAGS_REQUEST',
  LIST_ATTRIBUTE_TAGS_SUCCESS: 'LIST_ATTRIBUTE_TAGS_SUCCESS',
  LIST_ATTRIBUTE_TAGS_FAILURE: 'LIST_ATTRIBUTE_TAGS_FAILURE',

  LIST_INGREDIENTS_REQUEST: 'LIST_INGREDIENTS_REQUEST',
  LIST_INGREDIENTS_SUCCESS: 'LIST_INGREDIENTS_SUCCESS',
  LIST_INGREDIENTS_FAILURE: 'LIST_INGREDIENTS_FAILURE',

  UPDATE_PACK_REQUEST: 'UPDATE_PACK_REQUEST',
  UPDATE_PACK_SUCCESS: 'UPDATE_PACK_SUCCESS',
  UPDATE_PACK_FAILURE: 'UPDATE_PACK_FAILURE',

  LIST_PACK_RECIPES_REQUEST: 'LIST_PACK_RECIPES_REQUEST',
  LIST_PACK_RECIPES_SUCCESS: 'LIST_PACK_RECIPES_SUCCESS',
  LIST_PACK_RECIPES_FAILURE: 'LIST_PACK_RECIPES_FAILURE',

  UPDATE_RECIPE_REQUEST: 'UPDATE_RECIPE_REQUEST',
  UPDATE_RECIPE_SUCCESS: 'UPDATE_RECIPE_SUCCESS',
  UPDATE_RECIPE_FAILURE: 'UPDATE_RECIPE_FAILURE',

  REMOVE_PACK_RECIPE_REQUEST: 'REMOVE_PACK_RECIPE_REQUEST',
  REMOVE_PACK_RECIPE_SUCCESS: 'REMOVE_PACK_RECIPE_SUCCESS',
  REMOVE_PACK_RECIPE_FAILURE: 'REMOVE_PACK_RECIPE_FAILURE',

  CREATE_RECIPE_REQUEST: 'CREATE_RECIPE_REQUEST',
  CREATE_RECIPE_SUCCESS: 'CREATE_RECIPE_SUCCESS',
  CREATE_RECIPE_FAILURE: 'CREATE_RECIPE_FAILURE',

  LIST_RECIPE_INGREDIENTS_REQUEST: 'LIST_RECIPE_INGREDIENTS_REQUEST',
  LIST_RECIPE_INGREDIENTS_SUCCESS: 'LIST_RECIPE_INGREDIENTS_SUCCESS',
  LIST_RECIPE_INGREDIENTS_FAILURE: 'LIST_RECIPE_INGREDIENTS_FAILURE',

  UPDATE_RECIPE_INGREDIENTS_REQUEST: 'UPDATE_RECIPE_INGREDIENTS_REQUEST',
  UPDATE_RECIPE_INGREDIENTS_SUCCESS: 'UPDATE_RECIPE_INGREDIENTS_SUCCESS',
  UPDATE_RECIPE_INGREDIENTS_FAILURE: 'UPDATE_RECIPE_INGREDIENTS_FAILURE',

  CREATE_RECIPE_INGREDIENTS_REQUEST: 'CREATE_RECIPE_INGREDIENTS_REQUEST',
  CREATE_RECIPE_INGREDIENTS_SUCCESS: 'CREATE_RECIPE_INGREDIENTS_SUCCESS',
  CREATE_RECIPE_INGREDIENTS_FAILURE: 'CREATE_RECIPE_INGREDIENTS_FAILURE',

  CREATE_RECIPE_TWIST_REQUEST: 'CREATE_RECIPE_TWIST_REQUEST',
  CREATE_RECIPE_TWIST_SUCCESS: 'CREATE_RECIPE_TWIST_SUCCESS',
  CREATE_RECIPE_TWIST_FAILURE: 'CREATE_RECIPE_TWIST_FAILURE',

  DELETE_RECIPE_INGREDIENTS_REQUEST: 'DELETE_RECIPE_INGREDIENTS_REQUEST',
  DELETE_RECIPE_INGREDIENTS_SUCCESS: 'DELETE_RECIPE_INGREDIENTS_SUCCESS',
  DELETE_RECIPE_INGREDIENTS_FAILURE: 'DELETE_RECIPE_INGREDIENTS_FAILURE',

  LIST_METHOD_STEPS_REQUEST: 'LIST_METHOD_STEPS_REQUEST',
  LIST_METHOD_STEPS_SUCCESS: 'LIST_METHOD_STEPS_SUCCESS',
  LIST_METHOD_STEPS_FAILURE: 'LIST_METHOD_STEPS_FAILURE',

  ASSET_UPLOAD_REQUEST: 'ASSET_UPLOAD_REQUEST',
  ASSET_UPLOAD_SUCCESS: 'ASSET_UPLOAD_SUCCESS',
  ASSET_UPLOAD_FAILURE: 'ASSET_UPLOAD_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  EDIT_METHOD_STEP_REQUEST: 'EDIT_METHOD_STEP_REQUEST',
  EDIT_METHOD_STEP_SUCCESS: 'EDIT_METHOD_STEP_SUCCESS',
  EDIT_METHOD_STEP_FAILURE: 'EDIT_METHOD_STEP_FAILURE',

  CREATE_METHOD_STEP_REQUEST: 'CREATE_METHOD_STEP_REQUEST',
  CREATE_METHOD_STEP_SUCCESS: 'CREATE_METHOD_STEP_SUCCESS',
  CREATE_METHOD_STEP_FAILURE: 'CREATE_METHOD_STEP_FAILURE',

  DELETE_METHOD_STEP_REQUEST: 'DELETE_METHOD_STEP_REQUEST',
  DELETE_METHOD_STEP_SUCCESS: 'DELETE_METHOD_STEP_SUCCESS',
  DELETE_METHOD_STEP_FAILURE: 'DELETE_METHOD_STEP_FAILURE',

  DELETE_STEP_TASKS_REQUEST: 'DELETE_STEP_TASKS_REQUEST',
  DELETE_STEP_TASKS_SUCCESS: 'DELETE_STEP_TASKS_SUCCESS',
  DELETE_STEP_TASKS_FAILURE: 'DELETE_STEP_TASKS_FAILURE',

  DUPLICATE_PORTION_REQUEST: 'DUPLICATE_PORTION_REQUEST',
  DUPLICATE_PORTION_SUCCESS: 'DUPLICATE_PORTION_SUCCESS',
  DUPLICATE_PORTION_FAILURE: 'DUPLICATE_PORTION_FAILURE',

  LIST_TWISTS_REQUEST: 'LIST_TWISTS_REQUEST',
  LIST_TWISTS_SUCCESS: 'LIST_TWISTS_SUCCESS',
  LIST_TWISTS_FAILURE: 'LIST_TWISTS_FAILURE',
  LIST_TWISTS_CLEAR_DATA: 'LIST_TWISTS_CLEAR_DATA',
  TWIST_UPDATE_CLEAR_DATA: 'TWIST_UPDATE_CLEAR_DATA',

  UPDATE_TWIST_REQUEST: 'UPDATE_TWIST_REQUEST',
  UPDATE_TWIST_SUCCESS: 'UPDATE_TWIST_SUCCESS',
  UPDATE_TWIST_FAILURE: 'UPDATE_TWIST_FAILURE',
  UPDATE_TWIST_FIELDS: 'UPDATE_TWIST_FIELDS',

  LOGOUT: 'LOGOUT',

  CONVERT_METHOD_REQUEST: 'CONVERT_METHOD_REQUEST',
  CONVERT_METHOD_SUCCESS: 'CONVERT_METHOD_SUCCESS',
  CONVERT_METHOD_FAILURE: 'CONVERT_METHOD_FAILURE',
} as const;

export default actionTypes;
