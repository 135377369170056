import { useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import { useSelector } from 'react-redux';

import {
  GlobalState,
  Ingredient,
  IngredientQuantity,
  IngredientTypes,
  PackDetail,
  RecipeIngredient,
  Unit,
  UnitState,
} from 'types';
import { isDefined } from 'utils';

import { EQUIPMENT_TYPE_NAME, ShoppingListItem } from 'types';

interface RecipeIngredientWithIngredient extends Omit<RecipeIngredient, 'ingredient'> {
  ingredient: number;
}

export function ingredientIsDefined(
  recipeIngredient: RecipeIngredient,
): recipeIngredient is RecipeIngredientWithIngredient {
  return recipeIngredient.ingredient != null;
}

export const WET_TYPES = [
  IngredientTypes.alcohol,
  IngredientTypes.carbsGrainsSugars,
  IngredientTypes.dairyEggs,
  IngredientTypes.fruit,
  IngredientTypes.meatFishShellfish,
  IngredientTypes.nonFood,
  IngredientTypes.nutsSeedsDriedFruits,
  IngredientTypes.staple,
  IngredientTypes.tinsJarsBottles,
] as IngredientTypes[];

export const DRY_TYPES = [
  IngredientTypes.seasonings,
  IngredientTypes.seasoningsSpices,
] as IngredientTypes[];

export const ALL_INGREDIENT_TYPES = [...WET_TYPES, ...DRY_TYPES] as IngredientTypes[];

export const CONVERSION_MULTIPLIERS = {
  mass: {
    // Dry:
    // 1tsp = 2g or 0.071oz
    // 1tbsp = 7g or 0.247oz
    //
    // Wet:
    // 1tsp = 5g or 0.176oz
    // 1tbsp = 15g or 0.529oz
    si: { dry: { tsp: 2, tbsp: 7 }, wet: { tsp: 5, tbsp: 15 } },
    imperial: {
      dry: { tsp: 0.071, tbsp: 0.247 },
      wet: { tsp: 0.176, tbsp: 0.529 },
    },
  },
  volume: {
    // Dry:
    // 1tsp = 2ml or 0.167fl oz
    // 1tbsp = 7ml or 0.5fl oz
    //
    // Wet:
    // 1tsp = 5ml or 0.167fl oz
    // 1tbsp = 15ml or 0.5fl oz
    si: { dry: { tsp: 2, tbsp: 7 }, wet: { tsp: 5, tbsp: 15 } },
    imperial: {
      dry: { tsp: 0.167, tbsp: 0.507 },
      wet: { tsp: 0.167, tbsp: 0.507 },
    },
  },
} as const;

/**
 *
 * @param ingredient
 * @param fromQuantity
 * @param fromUnit
 * @returns
 */
export const convertRecipeIngredientValues = (
  ingredient: Ingredient,
  ingredientUnitType: string,
  fromQuantity: number,
  fromUnit: Unit,
) => {
  // If the ingredient unit type isn't mass or volume then we can't convert it.
  if (ingredientUnitType !== 'mass' && ingredientUnitType !== 'volume') {
    return null;
  }

  // If the input ingredient type isn't in our type map then it likely doesn't need to be converted.
  if (!ingredient?.type || !ALL_INGREDIENT_TYPES.includes(ingredient.type.name)) {
    return null;
  }

  // This function can only convert tsp/tbsp -> other units.
  if (fromUnit.name !== 'tsp' && fromUnit.name !== 'tbsp') {
    return null;
  }

  // Decide the ingredient type to use when applying a conversion multiplier
  const ingredientType = DRY_TYPES.includes(ingredient.type.name) ? 'dry' : 'wet';

  // Convert the incoming tsp/tbsp amount into the correct amount based on
  // the knowledge of what measurement system we're converting from.

  const metricMultiplier =
    CONVERSION_MULTIPLIERS[ingredientUnitType]['si'][ingredientType][fromUnit.name];
  const metricValue = fromQuantity * metricMultiplier;

  const imperialMultiplier =
    CONVERSION_MULTIPLIERS[ingredientUnitType]['imperial'][ingredientType][fromUnit.name];
  const imperialValue = fromQuantity * imperialMultiplier;

  return {
    metric: { unit: { name: ingredientUnitType === 'mass' ? 'g' : 'ml' }, value: metricValue },
    imperial: {
      unit: { name: ingredientUnitType === 'mass' ? 'oz' : 'floz' },
      value: imperialValue,
    },
  };
};

export type IngWithQuantities = {
  ingredient: Ingredient;
  quantities: IngredientQuantity[];
};

type CombinedRecipeIngredients = Record<number, IngWithQuantities>;

/**
 * Turn the list of all recipeIngredients into a single list combining
 * ingredient quantities into a single value per ingredient (the sum of all
 * quantities for that ingredient in the list)
 * @param recipeIngredients RecipeIngredient[]
 * @param ingredients Ingredient[]
 * @returns CombinedRecipeIngredients
 */
export const combineRecipeIngredients = (args: {
  recipeIngredients: RecipeIngredient[];
  ingredients: Ingredient[];
}): CombinedRecipeIngredients => {
  const { recipeIngredients, ingredients } = args;
  return (
    recipeIngredients
      // Start by mapping from : RecipeIngredient ---> to : IngWithQuantities
      .map(rIng => {
        if (!rIng.ingredient) return null;

        const ingredient = ingredients.find(i => i.id === rIng.ingredient);
        if (!ingredient) return null;

        const { quantities } = rIng;
        if (!quantities?.length) return null;

        return { ingredient, quantities };
      })
      // Filter out all null values
      .filter(isDefined)
      // Reduce into mapping : {[ingredientId] : { ingredient, ingQuantities } }
      // (where the quantity is a sum of all quantities in the list for this ingredient)
      .reduce((acc, curr) => {
        const { ingredient, quantities } = curr;
        const existingIngQuantities: IngredientQuantity[] = acc[ingredient.id]?.quantities || [];

        return {
          ...acc,
          [ingredient.id]: {
            ingredient,
            quantities: [...existingIngQuantities, ...quantities],
          },
        };
      }, {} as CombinedRecipeIngredients)
  );
};

/**
 * Turn a CombinedRecipeIngredients object into a list of ShoppingListItem objects
 * @param combinedRecipeIngredients CombinedRecipeIngredients
 * @returns ShoppingListItem[]
 */
export const generateShoppingListItems = (
  combinedRecipeIngredients: CombinedRecipeIngredients,
  units: UnitState,
) =>
  Object.values(combinedRecipeIngredients)
    .map(({ ingredient, quantities }) => {
      // If the ingredient doesn't have a type, then it's most likely equipment due to
      // historical data import reasons.
      if (!ingredient.type || ingredient.type.name === EQUIPMENT_TYPE_NAME) {
        return null;
      }

      if (!quantities.length) {
        return null;
      }
      const ingredientUnit = units[ingredient.unit.toString()];

      // Convert tsp/tbsp quantities into metric/imperial quantities
      const convertedQuantities = quantities
        .filter(q => q.unit.name === 'tsp' || q.unit.name === 'tbsp')
        .map(q =>
          convertRecipeIngredientValues(ingredient, ingredientUnit.type, q.quantity, q.unit),
        )
        .filter(isDefined);

      // Map all other quantities to standard quantities
      const standardQuantities = quantities
        .filter(q => q.unit.name !== 'tsp' && q.unit.name !== 'tbsp')
        .map(q => ({
          value: q.quantity,
          unit: q.unit,
        }));

      const impertialQuantities = [
        ...standardQuantities.filter(q => q.unit.system === 'imperial' || q.unit.system === null),
        ...convertedQuantities.map(q => q.imperial),
      ];

      const imperialAmount = impertialQuantities.reduce((acc, curr) => acc + curr.value, 0);
      const imperialMeasurement = impertialQuantities[0]?.unit.name || '';

      const metricQuantities = [
        ...standardQuantities.filter(q => q.unit.system === 'si' || q.unit.system === null),
        ...convertedQuantities.map(q => q.metric),
      ];
      const metricAmount = metricQuantities.reduce((acc, curr) => acc + curr.value, 0);
      const metricMeasurement = metricQuantities[0]?.unit.name || '';

      return {
        ingredienType: ingredient.category?.name || '',
        metricAmount,
        metricMeasurement,
        imperialAmount,
        imperialMeasurement,
        ingredientName: ingredient.name,
        prepInstructions: quantities[0]?.prepInstructions,
      } as ShoppingListItem;
    })
    .filter(isDefined);

/**
 * This hook takes a pack ID, then builds the shopping list data.
 * @param recipeIds
 */
export const useShoppingList = (packId: number) => {
  /** ------------------------------------------------- */
  /** ------------------- SELECTORS ------------------- */

  const pack: PackDetail = useSelector(
    (state: GlobalState) => state.pack.detail[packId.toString()],
  );
  const units = useSelector((state: GlobalState) => state.common.units);

  const packRIng = pack?.recipeIngredients;
  const recipeIngredients = packRIng ? Object.values(packRIng).flatMap(a => a) : [];

  const ingIds = recipeIngredients
    ?.filter(ingredientIsDefined)
    .map(r => r.ingredient?.toString())
    .filter(isDefined);

  const ingredientState = useSelector((state: GlobalState) => state.common.ingredients);
  const ingredients = ingIds.map(id => ingredientState[id]);

  /** ------------------------------------------------- */
  /** ----------------- COMPUTED DATA ----------------- */

  /**
   * Turn the list of all recipeIngredients into a single list combining
   * ingredient quantities into a single value per ingredient
   * (the sum of all quantities for that ingredient in the list)
   */

  const combinedRIng = useMemo(() => {
    return (
      recipeIngredients &&
      ingredients &&
      combineRecipeIngredients({
        recipeIngredients,
        ingredients,
      })
    );
  }, [pack?.id]);

  //  Turn the combinedRIng into a list of ShoppingListItem objects
  const data = useMemo(() => {
    return combinedRIng ? generateShoppingListItems(combinedRIng, units) : [];
  }, [pack?.id]);
  return data;
};
