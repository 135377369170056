import React, { memo } from "react";
import { HStack, Text } from "@chakra-ui/react";
import { AiIcon } from "theme/icon";

const FallbackImg = memo(function FallbackImg() {
    return (
      <HStack flex={1} justifyContent="center">
        <AiIcon name="FillCloseCircle" color="red.400" fontSize={18} />
        <Text>Image Not Found </Text>
      </HStack>
    );
});

export default FallbackImg;
