import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  HStack,
  Tooltip,
  TextProps,
  StackProps,
} from "@chakra-ui/react";
import { HiIcon } from "theme/icon";

interface Props extends StackProps {
  value: string | number | null | undefined;
  label?: string;
  fontSize?: TextProps["fontSize"];
  textColor?: string;
  status?: string | null;
  tooltipText?: string | null;
  tooltipBgColor?: string;
  cursor?: string;
  onClick?: () => void;
}

const LabelCalculation: React.FC<Props> = ({
  value = "-",
  label,
  fontSize = "md",
  textColor = "white",
  bgColor = "gray.500",
  px = "lg",
  py = "xs",
  cursor = "default",
  onClick = () => {},
  tooltipText,
  tooltipBgColor,
  status,
}) => {
  return (
    <Tooltip hasArrow label={tooltipText} bg={tooltipBgColor}>
      <FormControl
        cursor={cursor}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        onClick={onClick}
      >
        {label ? <FormLabel>{label}</FormLabel> : null}
        <HStack
          flex={1}
          borderRadius="md"
          bgColor={bgColor}
          px={px}
          py={py}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Text fontSize={fontSize} color={textColor}>
            {typeof value === "number" ? Math.round(value * 100) / 100 : value}
          </Text>
          {status === "success" ? (
            <HiIcon
              name="CheckCircle"
              color="green.400"
              fontSize={18}
              cursor="pointer"
            />
          ) : status === "warning" ? (
            <HiIcon
              name="OutlineExclamationCircle"
              color="yellow.400"
              fontSize={18}
              cursor="pointer"
            />
          ) : null}
        </HStack>
      </FormControl>
    </Tooltip>
  );
};

export default LabelCalculation;
