import { Action, TwistsState } from "types";
import actionTypes from "redux/actionTypes";

export const twistsReducer = (
  state: TwistsState = {
    twists: [],
  },
  action: Action
): TwistsState => {
  switch (action.type) {
    case actionTypes.LIST_TWISTS_CLEAR_DATA: {
      return {
        ...state,
        twists: [],
      };
    }
    case actionTypes.LIST_TWISTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        twists: [...state.twists, ...payload.results],
      };
    }
    case actionTypes.UPDATE_TWIST_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        twists: state.twists.filter((twist) =>
          twist.id === payload.id ? twist.approved === payload.approved : true
        ),
      };
    }

    case actionTypes.UPDATE_TWIST_FIELDS: {
      const { payload } = action;
      return {
        ...state,
        twists: state.twists.map((twist) =>
          twist.id === payload.id ? payload : twist
        ),
      };
    }

    default:
      return state;
  }
};
