import { Action, CommonState } from "types";
import actionTypes from "redux/actionTypes";

import reduceArr from "utils/reduceArr";

export const commonReducer = (
  state: CommonState = {
    packThemes: {},
    cuisineTags: {},
    attributeTags: {},
    ingredients: {},
    units: {},
  },
  action: Action
): CommonState => {
  switch (action.type) {
    case actionTypes.LIST_CUISINE_TAGS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        cuisineTags: {
          ...state.cuisineTags,
          ...reduceArr(payload, "id")
        },
      };
    }
    case actionTypes.LIST_PACK_THEMES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        packThemes: {
          ...state.packThemes,
          ...reduceArr(payload, "id")
        },
      };
    }
    case actionTypes.LIST_ATTRIBUTE_TAGS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        attributeTags: {
          ...state.attributeTags,
          ...reduceArr(payload, "id")
        },
      };
    }
    case actionTypes.LIST_INGREDIENTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          ...reduceArr(payload, "id")
        },
      };
    }
    case actionTypes.LIST_UNITS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        units: {
          ...state.units,
          ...reduceArr(payload, "id"),
        }
      }
    }
    default:
      return state;
  }
};
