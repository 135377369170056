import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { GlobalState } from 'types';

import routes from './routes';

const PrivateRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useSelector((state: GlobalState) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to={routes.login} />;
};

export default PrivateRoute;
