import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  ModalHeader,
  Stack,
  HStack,
  Divider,
  Spinner,
  Progress,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { Accept, useDropzone } from "react-dropzone";
import { AiIcon, HiIcon } from "theme/icon";
import { CSVLink } from "react-csv";

export type FileStatus = "pending" | "isUploading" | "success" | "failure";

interface UploadFile {
  file: File;
  status: FileStatus;
  message?: string;
}

const buildExportData = (files: UploadFile[]) => {
  return files.map(({ file, message, status }) => ({
    Name: file.name,
    Message: message,
    Status: status,
  }));
};

const StatusIcon: React.FC<{ status: FileStatus }> = ({ status }) => {
  if (status === "pending")
    return <AiIcon name="FillMinusCircle" color="yellow.400" />;

  if (status === "isUploading") return <Spinner size="sm" />;

  if (status === "success")
    return <HiIcon name="CheckCircle" color="green.400" fontSize={18} />;

  return <AiIcon name="FillCloseCircle" color="red.400" fontSize={18} />;
};

interface Props {
  label?: string;
  isOpen: boolean;
  onClose: () => void;
  accept?: Accept;
  isUploading?: boolean;
  files: UploadFile[];
  onDrop?: (acceptedFiles: File[]) => void;
  removeFile?: (name: string) => void;
  onUpload?: () => void;
  cancelUpload?: () => void;
  onClear?: (status?: FileStatus) => void;
}

const AssetImportModal: React.FC<Props> = ({
  isOpen,
  onClose,
  accept,
  files,
  isUploading = false,
  removeFile = () => {},
  onDrop = () => {},
  onUpload = () => {},
  cancelUpload = () => {},
  onClear = () => {},
  label = "Drag Files Here",
}) => {
  const innerOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: innerOnDrop,
    accept,
  });

  const totalFiles = files.length;

  const calculateProgress = () => {
    const filesUploaded = files.filter((f) =>
      ["success"].includes(f.status)
    ).length;

    return Math.floor((filesUploaded / totalFiles || 0) * 100);
  };

  const noOfFilesToUpload = files.filter((f) =>
    ["pending", "failure"].includes(f.status)
  ).length;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Asset Import</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            bg="background.tint3"
            flexDir="column"
            alignItems="center"
            p={3}
            py={8}
            borderRadius="md"
            flex={1}
            cursor="pointer"
            {...getRootProps()}
          >
            <AiIcon name="OutlineCloudDownload" fontSize={32} />
            <Text fontSize="sm" fontWeight="semibold" color="text.muted" mb="3">
              {label}
            </Text>
            <Text fontSize="sm" fontWeight="semibold" color="text.muted" mb="3">
              or
            </Text>

            <input
              id="file-upload"
              type="file"
              style={{ display: "none" }}
              {...getInputProps()}
            />
            <Button size="sm">Select from File</Button>
          </Flex>
          <Divider mb="sm" />
          {isUploading && (
            <HStack alignItems="center" mt={10}>
              <Progress value={calculateProgress()} flex={1} />
              <Text fontSize="sm" fontWeight="semibold" color="text.muted">
                {calculateProgress()}%
              </Text>
            </HStack>
          )}
          <Stack mt="sm" maxHeight="40vh" overflowY="scroll">
            {files?.map(({ file, status, message }, key) => {
              return (
                <Flex key={key}>
                  <HStack
                    alignItems="center"
                    justifyContent="space-between"
                    flex={1}
                    opacity={isUploading ? 0.5 : 1}
                    spacing="sm"
                  >
                    <HStack alignItems="center" flex={2}>
                      <Flex>{status && <StatusIcon status={status} />}</Flex>
                      <Flex flex={1}>
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color="text.muted"
                          ml="xxs"
                          isTruncated
                        >
                          {file.name}
                        </Text>
                      </Flex>
                    </HStack>
                    <Flex
                      flex={3}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        color={
                          status === "failure"
                            ? "red.400"
                            : status === "success"
                            ? "green.400"
                            : "black.400"
                        }
                      >
                        {status === "isUploading" ? "Uploading..." : message}
                      </Text>
                    </Flex>
                    <Button
                      onClick={() => !isUploading && removeFile(file.name)}
                      disabled={isUploading}
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                    >
                      Clear
                    </Button>
                  </HStack>
                </Flex>
              );
            })}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {files.length && (
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<HiIcon name="ChevronDown" fontSize={18} />}
                  disabled={isUploading}
                >
                  Clear
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => onClear()}>
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="text.muted"
                    >
                      All
                    </Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    icon={
                      <HiIcon
                        name="CheckCircle"
                        color="green.400"
                        fontSize="md"
                      />
                    }
                    onClick={() => onClear("success")}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="text.muted"
                    >
                      Successful Uploads
                    </Text>
                  </MenuItem>
                  <MenuItem
                    icon={
                      <AiIcon
                        name="FillCloseCircle"
                        color="red.400"
                        fontSize="md"
                      />
                    }
                    onClick={() => onClear("failure")}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="text.muted"
                    >
                      Failed Uploads
                    </Text>
                  </MenuItem>
                  <MenuItem
                    icon={
                      <AiIcon
                        name="FillMinusCircle"
                        color="yellow.400"
                        fontSize="md"
                      />
                    }
                    onClick={() => onClear("pending")}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="text.muted"
                    >
                      Pending Uploads
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            <Button
              mr={3}
              onClick={isUploading ? cancelUpload : onUpload}
              isDisabled={totalFiles === 0}
            >
              {isUploading
                ? "Cancel Upload"
                : `Upload${
                    noOfFilesToUpload > 0
                      ? ` ${noOfFilesToUpload} File${
                          noOfFilesToUpload > 1 ? "s" : ""
                        }`
                      : ""
                  }`}
            </Button>
            {noOfFilesToUpload && (
              <CSVLink
                data={buildExportData(files)}
                filename={"asset_upload_results.csv"}
              >
                <Button>Export as CSV</Button>
              </CSVLink>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssetImportModal;
