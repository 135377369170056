import actionTypes from "redux/actionTypes";
import {
  Action,
  Quantity,
  RecipeState
} from "types";

import reduceArr from "utils/reduceArr";

export const recipeReducer = (
  state: RecipeState = {
    list: {},
    ingredients: {},
    methodSteps: {}
  },
  action: Action
): RecipeState => {
  switch (action.type) {
    case actionTypes.LIST_RECIPES_CLEAR: {
      return {
        ...state,
        list: {}
      }
    }
    case actionTypes.LIST_PACK_RECIPES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        list: {
          ...state.list,
          ...reduceArr(payload, "id")
        }
      }
    }
    case actionTypes.LIST_RECIPES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        list: {
          ...state.list,
          ...reduceArr(payload.results, "id")
        }
      }
    }
    case actionTypes.REMOVE_PACK_RECIPE_SUCCESS:
    case actionTypes.RETRIEVE_RECIPE_SUCCESS:
    case actionTypes.UPDATE_PACK_SUCCESS:
    case actionTypes.CREATE_RECIPE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        list: {
          ...state.list,
          [payload.id]: {
            ...payload
          }
        }
      }
    }
    case actionTypes.LIST_RECIPE_INGREDIENTS_SUCCESS:
    case actionTypes.UPDATE_RECIPE_INGREDIENTS_SUCCESS:
    case actionTypes.CREATE_RECIPE_INGREDIENTS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          ...reduceArr(payload, "id")
        }
      }
    }
    case actionTypes.DUPLICATE_PORTION_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ingredients: {
          ...reduceArr(payload.recipeIngredients, "id")
        },
        methodSteps: {
          ...reduceArr(payload.methodSteps, "id")
        }
      }
    }
    case actionTypes.DELETE_RECIPE_INGREDIENTS_SUCCESS: {
      const { meta } = action

      // @ts-expect-error
      const {
        recipeIngredients
      }: {
        recipeIngredients: Array<{
          id: number
          quantities: Quantity[]
        }>
      } = meta

      // Create a copy of our existing state which we can mutate.
      //
      // Loop through the meta payload and remove the items in our
      // mutable state object which match the IDs of the deleted
      // entities.
      const updatedIngredients = { ...state.ingredients }
      recipeIngredients.forEach((recipeIng: { id: number }) => {
        delete updatedIngredients[recipeIng.id]
      })

      return {
        ...state,
        ingredients: updatedIngredients
      }
    }
    case actionTypes.LIST_METHOD_STEPS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        methodSteps: {
          ...state.methodSteps,
          ...reduceArr(payload, "id")
        }
      }
    }
    case actionTypes.CREATE_METHOD_STEP_SUCCESS:
    case actionTypes.EDIT_METHOD_STEP_SUCCESS: {
      const { payload } = action
      const { id } = payload
      if (id) {
        return {
          ...state,
          methodSteps: {
            ...state.methodSteps,
            [id.toString()]: payload
          }
        }
      }
      return state
    }
    case actionTypes.DELETE_METHOD_STEP_SUCCESS: {
      const { meta } = action
      const { id } = meta

      // Create a copy of our existing state which we can mutate.
      //
      // Remove the deleted method step
      const updatedMethodSteps = { ...state.methodSteps }
      delete updatedMethodSteps[id]

      return {
        ...state,
        methodSteps: updatedMethodSteps
      }
    }
    case actionTypes.DELETE_STEP_TASKS_SUCCESS: {
      const { meta } = action
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const { tasks, method }: { tasks: number[], method: number } = meta

      // Create a copy of our method & task list which we can mutate
      //
      // Remove the deleted tasks
      const updatedMethod = { ...state.methodSteps[method] }
      // If a task has been deleted (i.e. the `tasks` array), then remove
      // it from our list of existing tasks
      updatedMethod.tasks = [...updatedMethod.tasks].filter((t) => t.id != null && !tasks.includes(t.id))

      return {
        ...state,
        methodSteps: {
          ...state.methodSteps,
          [method.toString()]: updatedMethod
        }
      }
    }
    case actionTypes.UPDATE_RECIPE_SUCCESS: {
      const { payload: recipe } = action

      const recipeId = recipe.id
      const updatedRecipe = state.list[recipeId] ? { ...state.list[recipeId], ...recipe } : recipe

      return { ...state, list: { ...state.list, [recipeId]: updatedRecipe } }
    }
    default:
      return state
  }
}
