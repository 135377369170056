import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Select,
  SelectProps,
} from "@chakra-ui/react";
import { UseFormRegister, RegisterOptions, FieldPath, FieldValues } from "react-hook-form";

interface OptionType {
  label: string;
  value: string | number;
}

interface Props<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>
  extends SelectProps {
  name: TFieldName;
  register: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions<TFieldValues, TFieldName>;
  options: (OptionType | string)[];
  label?: string;
  helpText?: string;
  errorText?: string;
}

function LabelSelect<
  TFieldValues extends Record<string, any>,
  TFieldName extends FieldPath<TFieldValues>
>({
  name,
  options,
  register,
  registerOptions = {},
  label,
  helpText,
  errorText,
  placeholder,
  defaultValue,
  isInvalid = false,
  isDisabled = false,
  size = "md",
  ...rest
}: Props<TFieldValues, TFieldName>) {
  return (
    <FormControl isDisabled={isDisabled} isInvalid={isInvalid}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Select
        {...register(name, registerOptions)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        name={name}
        size={size}
        bgColor="white"
        {...rest}
      >
        {options.map((option, key) =>
          typeof option === "string" ? (
            <option key={key} value={option}>
              {option}
            </option>
          ) : (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          )
        )}
      </Select>
      {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
      <FormErrorMessage>{errorText}</FormErrorMessage>
    </FormControl>
  );
}

export default LabelSelect;
