import React, { ChangeEvent, Fragment, useEffect, useState, useCallback, useRef } from 'react';
import {
  Flex,
  Spinner,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  ThemeTypings,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import actionTypes from 'redux/actionTypes';
import { listPacks } from 'redux/actions/pack';
import { getParamFromString } from 'utils';
import { useDispatch } from 'hooks';

import routes from 'navigation/routes';

import { toast } from 'navigation/AppRouter';
import { ListPacksSuccess, Status, GlobalState } from 'types';

import ScreenWrapper from 'components/ScreenWrapper';
import Search from 'components/Search';
import LabelSelect from 'components/LabelSelect';

const statusColour = (status: Status): ThemeTypings['colorSchemes'] => {
  if (status === 'draft') return 'purple';
  if (status === 'published') return 'green';
  return 'gray';
};

const MealPacksListContainer: React.FC = () => {
  const packs = useSelector((state: GlobalState) => state.pack.list);

  const [statusFilter, setStatusFilter] = useState<Status | null>(null);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const searchText = useRef<string>('');
  // const nextRef = useRef<string | null>(null);

  const { register } = useForm<Partial<{ status: Status | null }>>({
    defaultValues: {
      status: null,
    },
  });

  const dispatch = useDispatch();

  const loadMore = async () => {
    if (!nextPage) {
      return;
    }
    const packsFilter = statusFilter ? `status=${statusFilter}` : undefined;

    const response = await dispatch(listPacks(nextPage, packsFilter, searchText.current));

    if (response.error) {
      toast({
        title: 'Error',
        description: 'An error occurred whilst loading more packs.',
        status: 'error',
      });
    } else {
      const { payload } = response;
      if (payload && 'next' in payload && payload.next) {
        // nextRef.current = payload.next ? getParamFromString(payload.next.split("?")[1], "page") : null;
        const page = getParamFromString(payload.next.split('?')[1], 'page');
        setNextPage(page);
      } else {
        setNextPage(null);
      }
    }
  };

  const loadPacks = useCallback(
    async (search?: string) => {
      setIsLoading(true);

      const packsFilter = statusFilter ? `status=${statusFilter}` : undefined;

      const response = await dispatch(listPacks('1', packsFilter, search));

      setIsLoading(false);

      if (response.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch packs',
          isClosable: true,
        });
        return;
      }

      const { payload } = response as ListPacksSuccess;

      if (payload.next) {
        const page = getParamFromString(payload.next.split('?')[1], 'page');
        setNextPage(page);
      } else {
        setNextPage(null);
      }
    },
    [statusFilter, searchText],
  );

  useEffect(() => {
    loadPacks();
  }, []);

  const handleStatusFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value as Status);
    dispatch({
      type: actionTypes.LIST_PACKS_CLEAR,
    });
  };

  useEffect(() => {
    setNextPage(null);
    loadPacks(searchText.current);
  }, [statusFilter]);

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    searchText.current = event.target.value;

    dispatch({
      type: actionTypes.LIST_PACKS_CLEAR,
    });
    loadPacks(event.target.value);
  }, 250);

  if (hasError) {
    return (
      <ScreenWrapper>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            An error occurred
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            There was an error loading your packs. Please refresh to try again.
          </AlertDescription>
        </Alert>
      </ScreenWrapper>
    );
  }

  const packsArray = Object.values(packs)
    .sort((a, b) => {
      if (a.status === b.status) {
        return a.code.localeCompare(b.code, undefined, { sensitivity: 'base' });
      }
      return a.status > b.status ? -1 : 1;
    })
    .filter(a => (statusFilter ? a.status === statusFilter : a));

  return (
    <ScreenWrapper>
      <Flex align="flex-end" mb="md">
        <Flex flex={1}>
          <LabelSelect
            name="status"
            label="Pack Status"
            register={register}
            defaultValue=""
            onChange={handleStatusFilterChange}
            options={[
              { value: '', label: 'Show all' },
              { value: 'do_not_use', label: 'Do Not Use' },
              { value: 'draft', label: 'Written Draft' },
              { value: 'labbed', label: 'Labbed & Amended' },
              { value: 'photos_uploaded', label: 'Photos Uploaded' },
              { value: 'ready_for_scripts', label: 'Ready For Scripts' },
              { value: 'audios_uploaded', label: 'Audios Uploaded' },
              { value: 'audios_proofed', label: 'Assets Proofed' },
              { value: 'published', label: 'Published' },
            ]}
            width="50%"
          />
        </Flex>
        <Flex flex={1}>
          <Search
            value=""
            isLoading={false}
            onSearchChange={handleSearch}
            resultRenderer={result => <Fragment />}
            onResultSelect={() => {}}
            shouldShowResults={false}
          />
        </Flex>
      </Flex>

      {isLoading ? (
        <ScreenWrapper>
          <Stack>
            {Array.from(Array(5)).map((_, idx) => (
              <Fragment key={idx}>
                <Skeleton height="40px" />
              </Fragment>
            ))}
          </Stack>
        </ScreenWrapper>
      ) : (
        <InfiniteScroll
          dataLength={packsArray.length}
          next={loadMore}
          hasMore={nextPage !== null}
          loader={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Spinner />
              <Text ml="sm">Loading more...</Text>
            </Flex>
          }
          endMessage={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Text textAlign="center" fontWeight="bold" fontSize="lg">
                Yay! You&apos;ve seen it all
              </Text>
            </Flex>
          }
        >
          <Stack>
            {packsArray.map(pack => (
              <LinkBox key={pack.id} role="group">
                <LinkOverlay
                  as={Link}
                  fontWeight="bold"
                  to={`${routes.packList}/${pack.id}`}
                  alignItems="center"
                  bgColor="gray.500"
                  borderRadius="md"
                  color="white"
                  cursor="pointer"
                  display="flex"
                  flex={1}
                  flexDir="row"
                  padding="xs"
                  paddingX="md"
                >
                  <Text flex={1}>
                    {pack.code} - {pack.name}
                  </Text>
                  <Badge
                    px="xs"
                    py="xxs"
                    mt="xxs"
                    colorScheme={statusColour(pack.status)}
                    borderRadius="sm"
                  >
                    {pack.status}
                  </Badge>
                </LinkOverlay>
              </LinkBox>
            ))}
          </Stack>
        </InfiniteScroll>
      )}
    </ScreenWrapper>
  );
};

export default MealPacksListContainer;
