// @ts-nocheck -> react-pdf causing issues
import React from 'react';
import { PDFViewer, Page, View, Document, StyleSheet, Text } from '@react-pdf/renderer';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';

// Create styles
export const styles = StyleSheet.create({
  page: {
    ViewDirection: 'row',
    backgroundColor: 'white',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    ViewGrow: 1,
  },
  code: {
    fontSize: 16,
    marginBottom: 5,
  },
  packCodeWrapper: {
    paddingLeft: 20,
    paddingBottom: 10,
  },
  packCode: {
    fontSize: 20,
  },
  title: {
    fontSize: 24,
  },
  // Sections
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  sectionSubtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
  },
  sectionItem: {
    fontSize: 12,
    marginBottom: 10,
  },
});

export interface DocumentProps {
  code: string;
  packCode?: string;
  packStory?: string;
  packTitle?: string;
  recipeStory?: string;
  numPeople?: number;
  title: string;
  sections: {
    title: string;
    items: string[];
    number?: number;
  }[];
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfData: DocumentProps | null;
}

const PDFDocument: React.FC<DocumentProps> = ({
  code,
  packCode,
  packTitle,
  packStory,
  title,
  numPeople,
  sections,
  recipeStory,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View fixed style={styles.packCodeWrapper}>
          <Text style={styles.code}>Pack: {packCode}</Text>
          {packTitle && <Text style={styles.code}>Pack Title: {packTitle}</Text>}
          {packStory && <Text style={styles.code}>Pack Story: {packStory}</Text>}
        </View>
        <View>
          <View style={styles.section}>
            <Text style={styles.code}>{code}</Text>
            <Text style={styles.title}>{title}</Text>
            {recipeStory !== '' && <Text style={styles.code}>{recipeStory}</Text>}
            {numPeople ? <Text>({numPeople} People)</Text> : null}
          </View>
          {sections.map((section, key) => (
            <View style={styles.section} key={key}>
              <Text style={styles.sectionTitle}>
                {section.number ? `${section.number} - ` : ''}
                {section.title}
              </Text>
              {section.items.map(item => (
                <Text style={styles.sectionItem} key={item}>
                  {item}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const PDFModal: React.FC<ModalProps> = ({ isOpen, onClose, pdfData }) => {
  if (!pdfData) return null;

  const { code, title, numPeople, sections, packCode, packStory, packTitle, recipeStory } = pdfData;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {code}
          <ModalCloseButton />
        </ModalHeader>

        <PDFViewer
          style={{
            width: '100%',
            height: '100vh',
            marginTop: 20,
          }}
        >
          <PDFDocument
            packCode={packCode}
            code={code}
            title={title}
            sections={sections}
            numPeople={numPeople}
            packTitle={packTitle}
            packStory={packStory}
            recipeStory={recipeStory}
          />
        </PDFViewer>
      </ModalContent>
    </Modal>
  );
};

export default PDFModal;
