import actionTypes from 'redux/actionTypes';
import { callAPI } from 'utils';
import API from 'constants/api';
import humps from 'humps';

import {
  ListRecipesAction,
  RetrieveRecipeAction,
  UpdateRecipeAction,
  CreateRecipeAction,
  ListRecipeIngredientsAction,
  UpdateRecipeIngredientsAction,
  DeleteRecipeIngredientsAction,
  CreateRecipeIngredientsAction,
  ListMethodStepsAction,
  Recipe,
  UpdateableMethodStep,
  EditMethodStepAction,
  CreateMethodStepAction,
  DeleteMethodStepAction,
  RecipeIngredient,
  Quantity,
  DuplicatePortionAction,
  ConvertMethodAction,
} from 'types';

/** START: RECIPE INGREDIENT POST TYPES */
interface UnitData {
  id?: number;
}

interface QuantityData extends Omit<Quantity, 'numPeople' | 'unit' | 'quantity'> {
  numPeople?: number | null;
  quantity?: number;
  unit: UnitData;
}

interface RecipeIngredientData extends Omit<RecipeIngredient, 'id' | 'quantities'> {
  id?: number | null;
  quantities: QuantityData[];
}
/** START: END INGREDIENT POST TYPES */

export const listRecipes = (
  page: number,
  sorting: string,
  recipeType: Recipe['recipeType'],
  search?: string,
) => {
  return callAPI<ListRecipesAction>({
    types: [
      actionTypes.LIST_RECIPES_REQUEST,
      actionTypes.LIST_RECIPES_SUCCESS,
      actionTypes.LIST_RECIPES_FAILURE,
    ],
    endpoint: API.recipeList(page, sorting, recipeType, search),
    method: 'GET',
  });
};

export const retrieveRecipe = (recipeId: number) => {
  return callAPI<RetrieveRecipeAction>({
    types: [
      actionTypes.RETRIEVE_RECIPE_REQUEST,
      actionTypes.RETRIEVE_RECIPE_SUCCESS,
      actionTypes.RETRIEVE_RECIPE_FAILURE,
    ],
    endpoint: API.recipes(recipeId),
    method: 'GET',
  });
};

export const updateRecipe = (recipeId: number, data: Partial<Recipe>) => {
  return callAPI<UpdateRecipeAction>({
    types: [
      actionTypes.UPDATE_RECIPE_REQUEST,
      actionTypes.UPDATE_RECIPE_SUCCESS,
      actionTypes.UPDATE_RECIPE_FAILURE,
    ],
    endpoint: API.recipes(recipeId),
    method: 'PATCH',
    body: humps.decamelizeKeys(data),
  });
};

export const createRecipe = (data: Partial<Recipe>) => {
  return callAPI<CreateRecipeAction>({
    types: [
      actionTypes.CREATE_RECIPE_REQUEST,
      actionTypes.CREATE_RECIPE_SUCCESS,
      actionTypes.CREATE_RECIPE_FAILURE,
    ],
    endpoint: API.recipes(),
    method: 'POST',
    body: humps.decamelizeKeys(data),
  });
};

export const listRecipeIngredients = (recipeId: number) => {
  return callAPI<ListRecipeIngredientsAction>({
    types: [
      actionTypes.LIST_RECIPE_INGREDIENTS_REQUEST,
      actionTypes.LIST_RECIPE_INGREDIENTS_SUCCESS,
      actionTypes.LIST_RECIPE_INGREDIENTS_FAILURE,
    ],
    endpoint: API.listCreateRecipeIngredients(recipeId),
    method: 'GET',
  });
};

export const updateRecipeIngredients = (data: Partial<RecipeIngredientData>[]) => {
  return callAPI<UpdateRecipeIngredientsAction>({
    types: [
      actionTypes.UPDATE_RECIPE_INGREDIENTS_REQUEST,
      actionTypes.UPDATE_RECIPE_INGREDIENTS_SUCCESS,
      actionTypes.UPDATE_RECIPE_INGREDIENTS_FAILURE,
    ],
    endpoint: API.bulkUpdateRecipeIngredients,
    method: 'PATCH',
    body: humps.decamelizeKeys(data),
  });
};

export const createRecipeIngredients = (
  recipeId: number,
  data: Partial<RecipeIngredientData>[],
) => {
  return callAPI<CreateRecipeIngredientsAction>({
    types: [
      actionTypes.CREATE_RECIPE_INGREDIENTS_REQUEST,
      actionTypes.CREATE_RECIPE_INGREDIENTS_SUCCESS,
      actionTypes.CREATE_RECIPE_INGREDIENTS_FAILURE,
    ],
    endpoint: API.listCreateRecipeIngredients(recipeId),
    method: 'POST',
    body: humps.decamelizeKeys(data),
  });
};

export const deleteRecipeIngredients = (
  data: {
    id: number;
    quantities: Quantity[];
  }[],
) => {
  return callAPI<DeleteRecipeIngredientsAction>({
    types: [
      actionTypes.DELETE_RECIPE_INGREDIENTS_REQUEST,
      actionTypes.DELETE_RECIPE_INGREDIENTS_SUCCESS,
      actionTypes.DELETE_RECIPE_INGREDIENTS_FAILURE,
    ],
    endpoint: API.bulkUpdateRecipeIngredients,
    method: 'DELETE',
    body: humps.decamelizeKeys(data),
    meta: { recipeIngredients: humps.decamelizeKeys(data) },
  });
};

export const listMethodSteps = (recipeMethod: number) => {
  return callAPI<ListMethodStepsAction>({
    types: [
      actionTypes.LIST_METHOD_STEPS_REQUEST,
      actionTypes.LIST_METHOD_STEPS_SUCCESS,
      actionTypes.LIST_METHOD_STEPS_FAILURE,
    ],
    endpoint: API.listCreateMethodSteps(recipeMethod),
    method: 'GET',
  });
};

export const editMethodStep = (id: number, data: UpdateableMethodStep) => {
  return callAPI<EditMethodStepAction>({
    types: [
      actionTypes.EDIT_METHOD_STEP_REQUEST,
      actionTypes.EDIT_METHOD_STEP_SUCCESS,
      actionTypes.EDIT_METHOD_STEP_FAILURE,
    ],
    endpoint: API.editDeleteMethodStep(id),
    method: 'PATCH',
    body: humps.decamelizeKeys(data),
  });
};

export const createMethodStep = (recipeMethodId: number, data: UpdateableMethodStep) => {
  return callAPI<CreateMethodStepAction>({
    types: [
      actionTypes.CREATE_METHOD_STEP_REQUEST,
      actionTypes.CREATE_METHOD_STEP_SUCCESS,
      actionTypes.CREATE_METHOD_STEP_FAILURE,
    ],
    endpoint: API.listCreateMethodSteps(recipeMethodId),
    method: 'POST',
    body: humps.decamelizeKeys(data),
  });
};

export const removeMethodStep = (id: number) => {
  return callAPI<DeleteMethodStepAction>({
    types: [
      actionTypes.DELETE_METHOD_STEP_REQUEST,
      actionTypes.DELETE_METHOD_STEP_SUCCESS,
      actionTypes.DELETE_METHOD_STEP_FAILURE,
    ],
    endpoint: API.editDeleteMethodStep(id),
    method: 'DELETE',
    meta: { id },
  });
};

export const deleteStepTasks = (ids: number[], methodStepId: number) => {
  return callAPI<DeleteMethodStepAction>({
    types: [
      actionTypes.DELETE_STEP_TASKS_REQUEST,
      actionTypes.DELETE_STEP_TASKS_SUCCESS,
      actionTypes.DELETE_STEP_TASKS_FAILURE,
    ],
    endpoint: API.bulkDeleteStepTasks,
    method: 'DELETE',
    body: ids,
    meta: { tasks: ids, method: methodStepId },
  });
};

export const duplicatePortion = (recipeId: number) => {
  return callAPI<DuplicatePortionAction>({
    types: [
      actionTypes.DUPLICATE_PORTION_REQUEST,
      actionTypes.DUPLICATE_PORTION_SUCCESS,
      actionTypes.DUPLICATE_PORTION_FAILURE,
    ],
    endpoint: API.duplicatePortion(recipeId),
    method: 'POST',
  });
};

export const convertMethod = (body: { method: string; boldOnly: boolean }) => {
  return callAPI<ConvertMethodAction>({
    types: [
      actionTypes.CONVERT_METHOD_REQUEST,
      actionTypes.CONVERT_METHOD_SUCCESS,
      actionTypes.CONVERT_METHOD_FAILURE,
    ],
    endpoint: API.convertMethod,
    method: 'POST',
    body: humps.decamelizeKeys(body),
  });
};
