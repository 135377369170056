import React from "react";
import {
  Textarea,
  TextareaProps,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { UseFormRegister, RegisterOptions, FieldPath, FieldValues } from "react-hook-form";

interface Props<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>
  extends TextareaProps {
  label?: string;
  register: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions<TFieldValues, TFieldName>;
  name: TFieldName;
  helpText?: string;
  errorText?: string;
}

function LabelTextArea<
  TFieldValues extends Record<string, any>,
  TFieldName extends FieldPath<TFieldValues>
>({
  id,
  name,
  register,
  registerOptions = {},
  label,
  helpText,
  errorText,
  placeholder,
  defaultValue,
  size = "md",
  minHeight = 200,
  isDisabled = false,
  isInvalid = false,
  ...rest
}: Props<TFieldValues, TFieldName>) {
  return (
    <FormControl id={id} isDisabled={isDisabled} isInvalid={isInvalid}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Textarea
        {...register(name, registerOptions)}
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        size={size}
        bgColor="white"
        minH={minHeight}
        {...rest}
      />
      {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
      <FormErrorMessage>{errorText}</FormErrorMessage>
    </FormControl>
  );
}

export default LabelTextArea;
