// @ts-nocheck -> react-pdf causing issues
import React from "react";
import { PDFViewer, Page, View, Document, Text } from "@react-pdf/renderer";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Stack,
} from "@chakra-ui/react";

import { styles, DocumentProps } from "./PDFModal";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfData: DocumentProps[];
  packCode: string;
  packTitle?: string;
  packStory?: string;
  recipeStory?: string;
}

const PDFDocument: React.FC<{ data: DocumentProps[] }> = ({ data }) => {
  const packTitle = data?.length ? data[0].packTitle : null;
  const packStory = data?.length ? data[0].packStory : null;
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.packCodeWrapper}>
          {packTitle ? <Text style={styles.code}>Pack Title: {packTitle}</Text> : null}
          {packStory ? <Text style={styles.code}>Pack Story: {packStory}</Text> : null}
        </View>
        {data.map(({ packCode, code, title, numPeople, sections, packTitle, packStory, recipeStory }, index) => {
          return (
            <View break={index !== 0} key={packCode}>
              <View fixed style={styles.packCodeWrapper}>
                <Text style={styles.code}>Pack: {packCode}</Text>
              </View>
              <View>
                <View style={styles.section}>
                  <Text style={styles.code}>{code}</Text>
                  <Text style={styles.title}>{title}</Text>
                  {
                    recipeStory !== '' && <Text style={styles.code}>{recipeStory}</Text>
                  }
                  {numPeople ? <Text>({numPeople} People)</Text> : null}
                </View>
                {sections.map((section, key) => (
                  <View style={styles.section} key={key}>
                    <Text style={styles.sectionTitle}>
                      {section.number ? `${section.number} - ` : ""}
                      {section.title}
                    </Text>
                    {section.items.map((item) => (
                      <Text style={styles.sectionItem} key={item}>{item}</Text>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

const PDFModalMultiple: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  pdfData,
  packCode,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {packCode}
          <ModalCloseButton />
        </ModalHeader>

        <PDFViewer
          style={{
            width: "100%",
            height: "100vh",
            marginTop: 20,
          }}
        >
          <PDFDocument data={pdfData} />
        </PDFViewer>
      </ModalContent>
    </Modal>
  );
};

export default PDFModalMultiple;
