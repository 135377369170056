import { theme as defaultTheme, extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  // colors: {
  //   brand: {
  //     100: "#f7fafc",
  //     // ...
  //     900: "#1a202c",
  //   },
  // },
  space: {
    xxs: defaultTheme.space[1],
    xs: defaultTheme.space[2],
    sm: defaultTheme.space[4],
    md: defaultTheme.space[5],
    lg: defaultTheme.space[8],
    xl: defaultTheme.space[10],
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: "white",
        },
      },
    },
  },
});
