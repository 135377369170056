interface Props {
  recipeCode: string;
  stepNumber: number;
  portion?: number;
  taskNumber?: number;
  suffix: string;
  isTimer?: boolean;
  isEnd?: boolean;
  timerDuration?: number;
}

const buildAssetString = ({
  recipeCode,
  stepNumber,
  portion,
  taskNumber,
  suffix,
  isTimer,
  isEnd,
  timerDuration,
}: Props) => {
  /**
   * Builds the asset string
   * e.g. R015-M&F_S4_4P_A1.mp3
   * [RECIPE_CODE - STEP_NUMBER - PORTION - TASK_NUMBER]
   */

  if (!portion) {
    return `${recipeCode}_S${stepNumber}${suffix}`;
  }

  const baseString = `${recipeCode}_S${stepNumber}_${portion}P_A${taskNumber}`;

  if (!isTimer) {
    return `${baseString}${suffix}`;
  }

  return isEnd
    ? `${baseString}_T${stepNumber}_END${suffix}`
    : `${baseString}_T${stepNumber}${timerDuration ? `_${timerDuration}` : ""}${suffix}`;
};

export default buildAssetString;
