import {
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Table,
  Tbody,
  Td,
  Textarea,
  Thead,
  Tr,
  Icon,
  FormControl
} from '@chakra-ui/react';
import React, {
  FC,
  useEffect,
  useState
} from 'react';
import { useFieldArray } from 'react-hook-form';
import Prompt from 'components/Prompt';
import { useForm } from '../../../hooks';
import {
  BsIcon
} from '../../../theme/icon';
import {
  Twist,
} from '../../../types';
import {MdDeleteForever} from 'react-icons/md';

export interface RecipeTwistFormRow {
  recipeTwistId: number | null | undefined;
  comment: string;
  contentType: string;
  title: string;
}

const FIELD_ARRAY_NAME_RECIPE_TWISTS = 'recipeTwists';

interface Props {
  recipeTwists: Twist[];
  isSubmitting: boolean;
  onSubmit: (values: {
    recipeTwists: RecipeTwistFormRow[],
    deletedTwists: RecipeTwistFormRow[],
  }) => Promise<void>;
}

const RecipeTwistForm:FC<Props> = ({
                                     recipeTwists,
  onSubmit,
  isSubmitting = false
                                   }) => {
  const defaultValues: RecipeTwistFormRow[] = [...recipeTwists].map(twist => {
    return {
      recipeTwistId: twist.id,
      comment: twist.comment,
      contentType: twist.contentType,
      title: twist.title
    }
  });

  const [deletedTwists, setDeletedTwists] = useState<RecipeTwistFormRow[]>([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    watch,
  } = useForm({
    defaultValues: {"recipeTwists": defaultValues},
  });


  const { fields, append, remove } = useFieldArray({
    name: "recipeTwists",
    control
  });

  const watchRecipeTwists = watch(FIELD_ARRAY_NAME_RECIPE_TWISTS);
  if (!watchRecipeTwists) return null;

  const { isDirty } = formState;

  const onDeleteTwistHandler = (twist: RecipeTwistFormRow, index: number) => {
    if (twist.recipeTwistId) {
      const newDeletedTwists = [...deletedTwists];
      newDeletedTwists.push(twist);
      setDeletedTwists(newDeletedTwists);
    }
    remove(index);
  } ;

  const onSubmitData = handleSubmit(async (values) => {
    await onSubmit({
      ...values,
      deletedTwists,
    })
  })

  useEffect(() => {
    if (!isSubmitting) {
      reset({
        recipeTwists: defaultValues,
      });
    }
  }, [isSubmitting])

  return(
    <>
      <Prompt
        when={isDirty}
        message="You have unsaved Changes. Are you sure you want to leave?"
      />
      <Heading as="h3" size="lg" mb="sm">
        Twists - Chefs&apos; tips
      </Heading>
      <form onSubmit={onSubmitData}>
        <Table flex={1} size="sm">
          <Thead flex={1}>
            <Tr>
              <Td fontWeight="bold">Title</Td>
              <Td fontWeight="bold">Description</Td>
              <Td fontWeight="bold"></Td>
              <Td />
            </Tr>
          </Thead>
          <Tbody>
            {
              fields.map((item, index) => {

                return (
                  <Tr
                    key={item.id}
                    css={`
                    td:first-of-type {
                      padding-left: 0;
                    }
                    td:last-child {
                      padding-right: 0;
                    }
                  `}
                  >
                    <Td>
                      <Input
                        type="hidden"
                        {...register(
                          `recipeTwists.${index}.recipeTwistId` as const
                        )}
                        value={item.recipeTwistId || undefined}
                      />
                      <FormControl isRequired>
                        <Input
                          {...register(
                            `recipeTwists.${index}.title` as const
                          )}
                          bgColor="white"
                          defaultValue={item.title}
                          required
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl isRequired>
                        <Textarea
                          {...register(
                            `recipeTwists.${index}.comment` as const
                          )}
                          bgColor="white"
                          defaultValue={item.comment}
                          required
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <Button colorScheme="red" onClick={() => onDeleteTwistHandler(item, index)}>
                        <Icon as={MdDeleteForever} />
                      </Button>
                    </Td>
                  </Tr>
                );
              })
            }
          </Tbody>
        </Table>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          paddingTop="md"
        >
          <HStack flex={1}>
            <>
              <Button
                onClick={() => append({})}
                leftIcon={<BsIcon name="PlusSquareFill" color="blue.500" />}
                variant="ghost"
              >
                Add row
              </Button>
            </>
          </HStack>
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !isDirty}
          >
            Save
          </Button>
        </Flex>
      </form>
    </>
  )
}

export default RecipeTwistForm;
