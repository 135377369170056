import React from 'react';
import { Table, Thead, Tbody, Tr, Td, Text } from '@chakra-ui/react';

import LabelCalculation from './LabelCalculation';
import { EquipmentType, ShoppingListItem } from 'types';

export type ShoppingListData = Record<
  EquipmentType,
  Record<
    number | 'none',
    // Grouped by ingredient ID, grouped by unit system
    Record<number, Record<'si' | 'imperial' | 'none', ShoppingListItem>>
  >
>;

interface Props {
  data: ShoppingListItem[];
}

const ShoppingList: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <Table size="sm">
        <Thead>
          <Tr>
            <Td fontWeight="bold">Ingredient</Td>
            <Td fontWeight="bold">Metric Amount</Td>
            <Td fontWeight="bold">Metric Measurement</Td>
            <Td fontWeight="bold">Imperial Amount</Td>
            <Td fontWeight="bold">Imperial Measurement</Td>
            <Td fontWeight="bold">Ingredient Type</Td>
            <Td fontWeight="bold">Prep Instruction</Td>
          </Tr>
        </Thead>
        <Tbody>
          {data
            .sort((a, b) => {
              // sort alphabetically by ingredient name
              return a.ingredientName.localeCompare(b.ingredientName);
            })
            .map((item, idx) => {
              return (
                <Tr key={idx}>
                  <Td>
                    <LabelCalculation value={item.ingredientName} />
                  </Td>
                  <Td>
                    <LabelCalculation value={item.metricAmount} />
                  </Td>
                  <Td>
                    <LabelCalculation value={item.metricMeasurement} />
                  </Td>
                  <Td>
                    <LabelCalculation value={item.imperialAmount} />
                  </Td>
                  <Td>
                    <LabelCalculation value={item.imperialMeasurement} />
                  </Td>
                  <Td>
                    <LabelCalculation value={item.ingredienType} />
                  </Td>
                  <Td>
                    {item.prepInstructions ? (
                      <LabelCalculation value={item.prepInstructions} />
                    ) : (
                      <Text>-</Text>
                    )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </div>
  );
};

export default ShoppingList;
