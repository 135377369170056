import * as H from 'history';

export function getParamFromLocation<S = H.LocationState>(
  location: H.Location<S>,
  search: string
): string | null {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get(search) ? urlParams.get(search) : null;
}

export function getParamFromString(
  location: string,
  search: string
): string | null {
  const urlParams = new URLSearchParams(location);
  return urlParams.get(search) ? urlParams.get(search) : null;
}