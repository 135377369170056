// --- This is the root file for all API calls --- //

// Uncomment the desired environment
// export const ENV = "local";
// export const ENV = 'dev';
export const ENV = "prod";

export const BACKEND_ENV = process.env.REACT_APP_BACKEND_ENV
  ? (process.env.REACT_APP_BACKEND_ENV as 'local' | 'dev' | 'prod')
  : ENV;

export const urlMap = {
  // URL for local development
  local: {
    baseUrl: 'http://localhost:8000',
    // baseUrl: "https://cook.sorted.club",
  },
  // URL for development
  dev: {
    baseUrl: process.env.REACT_APP_DEVELOPMENT_URL,
  },
  // URL for production
  prod: {
    baseUrl: process.env.REACT_APP_PRODUCTION_URL,
  },
};

// Default API prefix
export const baseUrl = urlMap[BACKEND_ENV].baseUrl;
export const apiPrefix = baseUrl + '/api/v1';

// Full production mode
export const FRONT_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'local';
