import { Action, PackState } from 'types';
import actionTypes from 'redux/actionTypes';

import reduceArr from 'utils/reduceArr';

export const packReducer = (
  state: PackState = {
    list: {},
    detail: {},
    summary: [],
  },
  action: Action,
): PackState => {
  switch (action.type) {
    case actionTypes.RETRIEVE_PACK_SUCCESS:
    case actionTypes.UPDATE_PACK_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        detail: { ...state.detail, [payload.id]: payload },
        list: { ...state.list, [payload.id]: payload },
      };
    }

    case actionTypes.CREATE_PACK_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        detail: { ...state.detail, ...reduceArr([payload], 'id') },
        list: { ...state.list, ...reduceArr([payload], 'id') },
      };
    }

    case actionTypes.LIST_PACKS_CLEAR: {
      return { ...state, list: {} };
    }

    case actionTypes.LIST_PACKS_SUCCESS: {
      const {
        payload: { results },
      } = action;
      return {
        ...state,
        list: { ...state.list, ...reduceArr(results, 'id') },
      };
    }

    case actionTypes.LIST_SUMMARY_PACKS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        summary: payload,
      };
    }

    default:
      return state;
  }
};
