import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useDispatch } from 'hooks';
import ScreenWrapper from '../../components/ScreenWrapper';
import { toast } from '../../navigation/AppRouter';
import { retrieveTwists, updateTwist } from '../../redux/actions/twists';
import actionTypes from '../../redux/actionTypes';
import { ListTwistsSuccess, TwistWithContentObject } from '../../types';

type SelectOptions = 'True' | 'False' | '';

export default function TwistsScreen() {
  const dispatch = useDispatch();
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [filter, setFilter] = useState<SelectOptions>('');

  // @ts-ignore
  const twists = useSelector(state => state.twists.twists) as TwistWithContentObject[];

  useEffect(() => {
    async function getTwists() {
      const response = await dispatch(retrieveTwists(1));
      if (response.error) {
        setHasError(true);
      } else {
        const { payload } = response as ListTwistsSuccess;
        if (payload.next) {
          setNextPage(2);
        }
      }
      setIsLoading(false);
    }

    getTwists();
  }, []);

  useEffect(() => {
    loadMore();
  }, [filter]);

  const loadMore = async () => {
    if (!nextPage) {
      return;
    }
    const response = await dispatch(retrieveTwists(nextPage, filter));

    if (response.error) {
      toast({
        title: 'Error',
        description: 'An error occurred whilst loading more twists.',
        status: 'error',
      });
    } else {
      const { payload } = response as ListTwistsSuccess;
      setNextPage(payload.next ? nextPage + 1 : null);
    }
  };

  const handleOnFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value as SelectOptions);
    setNextPage(1);
    dispatch({
      type: actionTypes.LIST_TWISTS_CLEAR_DATA,
    });
  };

  const onHandleFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    fieldName: 'comment' | 'name' | 'country',
  ) => {
    const twist = twists[index];

    dispatch({
      type: actionTypes.UPDATE_TWIST_FIELDS,
      payload: {
        ...twist,
        [fieldName]: e.target.value,
      },
    });
  };

  const onStatusButtonClickHandler = async (index: number, status: string) => {
    const twist = twists[index];

    const data = {
      comment: twist.comment,
      country: twist.country,
      name: twist.name,
      approved: status === 'accept' ? 'True' : 'False',
    };
    await dispatch(updateTwist(twist.id, data));
    toast({
      title: 'Twist updated!',
      description: 'Twist updated successfully',
      status: 'success',
      isClosable: true,
    });
  };

  if (isLoading) {
    return (
      <ScreenWrapper>
        <Stack>
          {Array.from(Array(5)).map((_, idx) => (
            <Fragment key={idx}>
              <Skeleton height="40px" />
            </Fragment>
          ))}
        </Stack>
      </ScreenWrapper>
    );
  }

  if (hasError) {
    return (
      <ScreenWrapper>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            An error occurred
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            There was an error loading twists. Please refresh to try again.
          </AlertDescription>
        </Alert>
      </ScreenWrapper>
    );
  }

  const filterTwists = (twist: TwistWithContentObject) => {
    let filterValue: boolean | null = null;
    if (filter === 'True') {
      filterValue = true;
    } else if (filter === 'False') {
      filterValue = false;
    }
    return twist.contentObject && twist.approved === filterValue;
  };

  const getTitle = (twist: TwistWithContentObject) => {
    const { name, title, code } = twist.contentObject;
    return `${code}-${name || title}`;
  };

  return (
    <ScreenWrapper>
      <Stack>
        <HStack spacing="md" align="stretch">
          <VStack spacing="xs" alignItems="flex-start">
            <Text fontWeight="bold">Filter by status</Text>
            <Select placeholder="Pending" value={filter} onChange={handleOnFilterChange}>
              <option value={'True'}>Approved</option>
              <option value={'False'}>Not-Approved</option>
            </Select>
          </VStack>
        </HStack>
        <InfiniteScroll
          dataLength={twists.length}
          hasMore={nextPage !== null}
          next={loadMore}
          loader={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Spinner />
              <Text ml="sm">Loading more...</Text>
            </Flex>
          }
          endMessage={
            <Flex alignItems="center" justifyContent="center" py="sm">
              <Text textAlign="center" fontWeight="bold" fontSize="lg">
                Yay! You&apos;ve seen it all
              </Text>
            </Flex>
          }
        >
          <Table flex={1} size="sm">
            <Thead flex={1}>
              <Tr>
                <Td width={'10%'} />
                <Td fontWeight="bold" width={'20%'}>
                  Recipe Code/Title
                </Td>
                <Td fontWeight="bold" width={'30%'}>
                  Comment
                </Td>
                <Td fontWeight="bold" width={'20%'}>
                  Name
                </Td>
                <Td fontWeight="bold" width={'10%'}>
                  Location
                </Td>
                <Td width={'10%'} />
              </Tr>
            </Thead>
            <Tbody>
              {twists.filter(filterTwists).map((twist, index) => {
                return (
                  <Tr key={`${twist.id}`}>
                    <Td width={'10%'}>
                      <Image
                        boxSize={'100px'}
                        borderRadius={10}
                        objectFit="cover"
                        src={twist.contentObject.images.thumbnail}
                        alt={getTitle(twist)}
                      />
                    </Td>
                    <Td width={'20%'}>
                      <Text>{getTitle(twist)}</Text>
                    </Td>
                    <Td width={'30%'}>
                      <Textarea
                        placeholder="Comment"
                        value={twist.comment}
                        onChange={val => onHandleFieldChange(val, index, 'comment')}
                      />
                    </Td>
                    <Td width={'20%'}>
                      <Input
                        placeholder="Name"
                        value={twist.name}
                        onChange={val => onHandleFieldChange(val, index, 'name')}
                      />
                    </Td>
                    <Td width={'10%'}>
                      <Input
                        placeholder="Location"
                        value={twist.country}
                        onChange={val => onHandleFieldChange(val, index, 'country')}
                      />
                    </Td>
                    <Td width={'10%'}>
                      <HStack>
                        <Button
                          colorScheme="green"
                          onClick={() => onStatusButtonClickHandler(index, 'accept')}
                        >
                          Accept
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => onStatusButtonClickHandler(index, 'reject')}
                        >
                          Reject
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </Stack>
    </ScreenWrapper>
  );
}
