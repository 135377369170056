import { useEffect } from "react";
import {
  FieldValues,
  useForm as baseUseForm,
  UseFormProps,
} from "react-hook-form";

const useHasUnsavedChanges = (isDirty: boolean) => {
  /** Before unloading the page check whether a form is in dirty state.
   * If so, alert user about unsaved changes.
   */
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.returnValue =
          "Are you sure you want to leave? You have unsaved changes.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);
};

/** Create a wrapper around react-use-form useForm that is automatically checking whether
 *  there are any unsaved changes a user should be aware of
 */
function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(useFormProps?: UseFormProps<TFieldValues, TContext>) {
  const methods = baseUseForm<TFieldValues, TContext>(useFormProps);
  useHasUnsavedChanges(methods.formState.isDirty);
  return methods;
}

export default useForm;
