import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { IoIosConstruct } from 'react-icons/io';

import ScreenWrapper from "components/ScreenWrapper";

const IngredientScreen = () => {
  return (
    <ScreenWrapper>
      <HStack flex={1} alignItems="center" justifyContent="center">
        <Text>Under Construction</Text>
        <IoIosConstruct />
      </HStack>
    </ScreenWrapper>
  );
};

export default IngredientScreen;
