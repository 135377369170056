import React, { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  VStack,
  Button,
  Text,
  Heading,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Image,
  Input,
  Stack,
  StackDivider,
  SimpleGrid,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { useFieldArray, Controller } from 'react-hook-form';
import { useForm } from 'hooks';

import { BasePack, Pack, Recipe } from 'types';
import { CALCULATED_ATTRIBUTES, SEASONS } from 'constants/data';

import FallbackImg from 'components/FallbackImg';
import LabelInput from 'components/LabelInput';
import LabelCalculation from 'components/LabelCalculation';
import LabelTextArea from 'components/LabelTextArea';
import LabelSelect from 'components/LabelSelect';
import Prompt from 'components/Prompt';

import { GrIcon, BsIcon, HiIcon, MdIcon } from 'theme/icon';

import routes from 'navigation/routes';

import { buildLabelCalculationProps } from '../Forms/RecipeMethodForm';

type FormProps = Omit<Partial<Recipe>, 'cookingTime' | 'equipmentItems' | 'recipeequipmentSet'> & {
  primaryCookingTime?: number;
  secondaryCookingTime?: number;
  recipeequipmentSet: Array<
    | {
        id?: number;
        recipe?: number;
        equipment: number;
        portion: 'primary' | 'secondary' | 'any';
        quantity: number;
      }
    | undefined
  >;
};

interface OptionType {
  label: string;
  value: number;
}

interface RecipeDetailFormProps {
  onSubmit: (values: Partial<Recipe>) => Promise<boolean>;
  recipe: Recipe;
  pack: Pack | BasePack | null;
  isLoading?: boolean;
  isSubmitting?: boolean;
  equipmentOptions: OptionType[];
  cuisineTags: string[];
  attributeTags: string[];
}

const Tag = ({ tag }: { tag: string }) => (
  <Heading
    as="h3"
    size="md"
    padding="xs"
    backgroundColor="blue.500"
    textAlign="center"
    color="white"
    borderRadius="3"
  >
    {tag}
  </Heading>
);

const RecipeDetailForm: React.FC<RecipeDetailFormProps> = ({
  onSubmit,
  recipe,
  pack,
  cuisineTags,
  attributeTags,
  isLoading = false,
  isSubmitting = false,
  equipmentOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, formState, reset, control, watch } = useForm<FormProps>({
    defaultValues: {
      packCode: pack?.code,
      status: recipe.status,
      code: recipe.code,
      title: recipe.title,
      primaryCookingTime: recipe.cookingTime?.[0]?.duration,
      secondaryCookingTime: recipe.cookingTime?.[1]?.duration,
      recipeequipmentSet: recipe.recipeequipmentSet || [],
      cuisineTags: recipe.cuisineTags || [],
      seasonalTags: recipe.seasonalTags || [],
      attributeTags: recipe.attributeTags || [],
      story: recipe.story,
      portionSize: recipe.portionSize,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipeequipmentSet',
  });

  const { isDirty, errors } = formState;
  const maxStoryLength = 150;

  const recipeType = watch('recipeType') || recipe.recipeType;

  const onSubmitData = handleSubmit(async data => {
    const { cookingTime: currentCookingTime } = recipe;
    const { primaryCookingTime, recipeequipmentSet, packCode, status, ...rest } = data;
    // When submitting the data, we need to translate the form `primaryCookingTime`
    // and `secondaryCookingTime` in to the correct format for sending over the
    // API as nested cooking time objects on the recipe.
    //
    // Start by taking a copy of the current recipe cooking times - this could be
    // empty.
    const cookingTime = currentCookingTime ? [...currentCookingTime] : [];

    // Merge the existing first item in the cooking time array with our primary
    // cooking time data from the form
    cookingTime[0] = {
      ...(cookingTime[0] ? cookingTime[0] : { numPeople: 2, recipe: recipe.id }),
      ...(primaryCookingTime ? { duration: primaryCookingTime } : {}),
    };

    const submitValues = {
      ...rest,
      // Ensure packCode is only set for recipes connected to a pack
      ...(recipeType === 'default' ? { packCode } : { packCode: null }),
      // Ensure status isn't set for recipes connected to a pack
      ...(recipeType === 'default' ? {} : { status }),
      cookingTime,
      recipeequipmentSet: recipeequipmentSet
        ? recipeequipmentSet
            .map(r => ({ ...r, recipe: recipe.id }))
            .filter(r => r.equipment && r.portion)
        : [],
    };

    // Adding TS ignore - filtering out undefined items
    // @ts-ignore
    const error = await onSubmit(submitValues);

    if (!error) reset(data);
  });

  return (
    <>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      <form onSubmit={onSubmitData}>
        <Heading as="h3" size="lg" mb="sm">
          Recipe Details
        </Heading>
        <Stack spacing="md" divider={<StackDivider borderColor="gray.200" />}>
          <HStack spacing="md" align="stretch">
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Recipe Information</Text>
              {recipeType !== 'default' && (
                <LabelSelect
                  name="status"
                  label="Recipe Status"
                  register={register}
                  options={[
                    { value: 'do_not_use', label: 'Do Not Use' },
                    { value: 'draft', label: 'Written Draft' },
                    { value: 'labbed', label: 'Labbed & Amended' },
                    { value: 'photos_uploaded', label: 'Photos Uploaded' },
                    { value: 'ready_for_scripts', label: 'Ready For Scripts' },
                    { value: 'audios_uploaded', label: 'Audios Uploaded' },
                    { value: 'audios_proofed', label: 'Assets Proofed' },
                    { value: 'published', label: 'Published' },
                  ]}
                  helpText="Only required for recipes which are not connected to a pack"
                />
              )}
              <LabelInput
                placeholder="Pack Code"
                name="packCode"
                register={register}
                label="Pack Code:"
                icon={
                  pack ? (
                    <LinkBox>
                      <LinkOverlay as={Link} to={`${routes.packList}/${pack.id}`}>
                        <GrIcon
                          name="NewWindow"
                          opacity={0.75}
                          cursor="pointer"
                          title="Navigate to Pack"
                          fontSize={18}
                        />
                      </LinkOverlay>
                    </LinkBox>
                  ) : null
                }
                isRequired={recipeType === 'default'}
                registerOptions={{
                  required: recipeType === 'default',
                }}
                isInvalid={Boolean(errors.packCode)}
                errorText={errors.packCode ? 'Please enter a pack code' : undefined}
              />
              <LabelInput
                placeholder="Recipe Code"
                name="code"
                register={register}
                registerOptions={{
                  required: true,
                }}
                label="Recipe Code:"
                errorText={errors.code ? 'Please enter a recipe code' : undefined}
                isInvalid={Boolean(errors.code)}
                isRequired
              />
              <Text>Recipe Type</Text>
              <Controller
                render={({ field: { onChange, onBlur, value } }) => {
                  const recipeTypeOptions = [
                    { label: 'Default', value: 'default' },
                    { label: 'Youtube', value: 'youtube' },
                    { label: 'Standalone', value: 'standalone' },
                  ];

                  const option = recipeTypeOptions.find(option => option.value === value);

                  return (
                    <Select
                      styles={{
                        container: base => ({
                          ...base,
                          width: '100%',
                          marginRight: '10px',
                        }),
                        control: base => ({
                          ...base,
                          height: '100%',
                        }),
                      }}
                      options={recipeTypeOptions}
                      onChange={e => {
                        onChange(e?.value);
                      }}
                      onBlur={onBlur}
                      value={option}
                    />
                  );
                }}
                name="recipeType"
                defaultValue={recipe.recipeType}
                control={control}
              />
              <LabelInput
                placeholder="Recipe Title"
                name="title"
                register={register}
                label="Recipe Title:"
              />
              <LabelInput
                placeholder="Recipe Portion Size"
                name="portionSize"
                register={register}
                label="Recipe Portion Size:"
                helpText="Serving suggestion. Typically set for YouTube recipes, e.g. '12 servings' or '8 muffins'."
              />
              <LabelTextArea
                placeholder="Recipe Story"
                name="story"
                register={register}
                registerOptions={{
                  validate: val => {
                    if (!val) return true;

                    if (val.length < maxStoryLength) {
                      return true;
                    }

                    return `Max length exceeded, you need to remove  ${
                      val.length - maxStoryLength
                    } characters`;
                  },
                }}
                label="Recipe Story"
                helpText={`Maximum of ${maxStoryLength} characters`}
                errorText={errors.story?.message}
                isInvalid={Boolean(errors.story)}
              />
            </VStack>
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Recipe Attributes</Text>
              <LabelInput
                label="Primary Cooking Time"
                name="primaryCookingTime"
                register={register}
                placeholder="Primary Cooking Time"
                isRequired
                registerOptions={{
                  required: true,
                }}
                isInvalid={Boolean(errors.primaryCookingTime)}
                errorText={
                  errors.primaryCookingTime ? 'Please enter a primary cooking time' : undefined
                }
                helpText="Cooking Time in minutes"
              />
              <LabelInput
                label="Secondary Cooking Time"
                name="secondaryCookingTime"
                register={register}
                placeholder="Secondary Cooking Time"
                isRequired
                registerOptions={{
                  required: true,
                }}
                isInvalid={Boolean(errors.secondaryCookingTime)}
                errorText={
                  errors.secondaryCookingTime ? 'Please enter a secondary cooking time' : undefined
                }
                helpText="Cooking Time in minutes"
              />
              {'freshIngredientCost' in recipe && (
                <VStack pt="lg">
                  <LabelCalculation
                    label="Fresh Ingredient Cost"
                    value={`£ ${recipe.freshIngredientCost}`}
                    status={recipe.budget ? 'success' : 'warning'}
                    tooltipText="Budget if < £4"
                  />
                  <LabelCalculation
                    label="Store Cupboard Ingredient Cost"
                    value={`£ ${recipe.storeCupboardIngredientCost}`}
                  />
                </VStack>
              )}
              <LabelCalculation
                cursor="pointer"
                fontSize="sm"
                px="sm"
                label="Recipe Header Photo"
                value={`${recipe.code}_M1.jpg`}
                {...buildLabelCalculationProps(recipe.image)}
                onClick={() => (recipe.image ? setIsOpen(true) : null)}
              />
            </VStack>
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Equipment</Text>
              {fields.map((item, index) => {
                const selectedEquipment = equipmentOptions.find(
                  option => option.value === item.equipment,
                );

                return (
                  <Flex w="100%" key={item.id || index}>
                    <Controller
                      control={control}
                      defaultValue={item.id || undefined}
                      name={`recipeequipmentSet.${index}.id` as const}
                      render={({ field: { value } }) => <Input value={value} type="hidden" />}
                    />
                    <Controller
                      control={control}
                      defaultValue={selectedEquipment?.value}
                      name={`recipeequipmentSet.${index}.equipment` as const}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const option = equipmentOptions.find(option => option.value === value);

                        return (
                          <Select
                            styles={{
                              container: base => ({
                                ...base,
                                width: '100%',
                                marginRight: '10px',
                              }),
                              control: base => ({
                                ...base,
                                height: '100%',
                              }),
                            }}
                            options={equipmentOptions}
                            onChange={e => onChange(e?.value)}
                            onBlur={onBlur}
                            value={option}
                          />
                        );
                      }}
                    />
                    <LabelInput
                      defaultValue={item.quantity || 1}
                      name={`recipeequipmentSet.${index}.quantity` as const}
                      register={register}
                      registerOptions={{ required: true }}
                    />
                    <Button
                      colorScheme="red"
                      leftIcon={<MdIcon name="RemoveCircleOutline" />}
                      ml="10px"
                      onClick={() => remove(index)}
                      variant="ghost"
                    />
                  </Flex>
                );
              })}

              <Button
                // @ts-ignore
                onClick={() => append({ id: undefined, portion: 'any' })}
                leftIcon={<BsIcon name="PlusSquareFill" color="blue.500" />}
                variant="ghost"
              >
                Add equipment
              </Button>
            </VStack>
          </HStack>
          <HStack spacing="md" align="stretch">
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Cuisine</Text>
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {Array.from(Array(4).keys()).map(idx => {
                  return (
                    <LabelSelect
                      key={`cuisineTags.${idx}`}
                      name={`cuisineTags.${idx}` as const}
                      register={register}
                      placeholder="Select Cuisine"
                      options={cuisineTags}
                      defaultValue={recipe.cuisineTags[idx] || '-'}
                    />
                  );
                })}
              </SimpleGrid>
            </VStack>
          </HStack>
          <HStack spacing="md" align="stretch">
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Seasonal Tags</Text>
              <SimpleGrid mt="xs" columns={2} spacing="sm">
                {Array.from(Array(2).keys()).map(idx => {
                  const defaultValue = recipe.seasonalTags ? recipe.seasonalTags[idx] : '-';
                  return (
                    <LabelSelect
                      key={`seasonal-tag-${idx}`}
                      name={`seasonalTags.${idx}` as const}
                      register={register}
                      placeholder="Select Season"
                      options={SEASONS}
                      defaultValue={defaultValue}
                    />
                  );
                })}
              </SimpleGrid>
            </VStack>
          </HStack>
          <HStack spacing="md" align="stretch">
            <VStack flex={1} spacing="xs" alignItems="flex-start">
              <Text fontWeight="bold">Attributes</Text>
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {Array.from(Array(4).keys()).map(idx => {
                  const defaultValue = recipe.attributeTags ? recipe.attributeTags[idx] : '-';
                  return (
                    <LabelSelect
                      key={`attribute-tag-${idx}`}
                      name={`attributeTags.${idx}` as const}
                      register={register}
                      placeholder="Select Attribute"
                      options={attributeTags}
                      defaultValue={defaultValue}
                      isDisabled={CALCULATED_ATTRIBUTES.includes(defaultValue)}
                    />
                  );
                })}
              </SimpleGrid>
            </VStack>
          </HStack>
          <Box>
            <Text fontWeight="bold" mb="3" mt="5">
              Dietary Tag (calculated)
            </Text>
            <Box display="inline-block">
              {'dietaryTag' in recipe && <Tag tag={recipe.dietaryTag} />}
            </Box>
            <Text fontWeight="bold" mb="3" mt="5">
              Nutritional Tags (calculated)
            </Text>
            {'nutritionalTags' in recipe && (
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {recipe.nutritionalTags.length ? (
                  recipe.nutritionalTags?.map(tag => {
                    return <Tag key={`nutritional-tag-${tag}`} tag={tag} />;
                  })
                ) : (
                  <Text>No nutritional tags</Text>
                )}
              </SimpleGrid>
            )}
          </Box>
        </Stack>
        <Flex flex={1} alignItems="flex-end" justifyContent="flex-end" paddingTop="md">
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isLoading || !isDirty}
          >
            Save
          </Button>
        </Flex>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody py={5}>
              {recipe.image ? (
                <Image src={recipe.image} borderRadius="sm" fallback={<FallbackImg />} />
              ) : (
                <VStack>
                  <HiIcon
                    name="OutlineExclamationCircle"
                    color="yellow.400"
                    fontSize={24}
                    cursor="pointer"
                  />
                  <Text>Image has not been uploaded yet for recipe {recipe.code}</Text>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </>
  );
};

export default RecipeDetailForm;
