import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

interface Props extends FlexProps {
  bgColor?: string;
  children: React.ReactNode;
}

const ScreenWrapper: React.FC<Props> = ({
  children,
  bgColor = "gray.50",
  ...rest
}) => {
  return (
    <Flex direction="column" flex={1} bgColor={bgColor}>
      <Flex
        flex={1}
        minW="3xl"
        flexDirection="column"
        mx={16}
        my={16}
        minH="100vh"
        {...rest}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default ScreenWrapper;
