import React from "react";
import { VStack, HStack, Heading, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import ScreenWrapper from "components/ScreenWrapper";
import routes from "navigation/routes";

const HomeContainer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ScreenWrapper>
      <VStack flex={1} alignItems="center" justifyContent="flex-start">
        <Heading>
          Welcome to the Sorted Packs CMS, what do you want to do?
        </Heading>
        <HStack pt={5}>
          <Button
            onClick={() => navigate(routes.packList)}
            colorScheme="blue"
          >
            View and Edit Meal Packs
          </Button>
          <Button
            onClick={() => navigate(routes.recipesList)}
            colorScheme="blue"
          >
            View and Edit Recipes
          </Button>
        </HStack>
      </VStack>
    </ScreenWrapper>
  );
};

export default HomeContainer;
