import React, { ChangeEvent, Fragment, useCallback, useEffect, useState, useRef } from 'react';
import {
  Text,
  Stack,
  Skeleton,
  LinkBox,
  LinkOverlay,
  VStack,
  Select,
  HStack,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';

import { ImStarFull } from 'react-icons/im';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'hooks';

import actionTypes from 'redux/actionTypes';
import { listRecipes } from 'redux/actions/recipe';
import { toast } from 'navigation/AppRouter';

import ScreenWrapper from 'components/ScreenWrapper';
import Search from 'components/Search';

import { GlobalState, ListRecipesSuccess, Recipe } from 'types';
import routes from 'navigation/routes';

type SelectOptions = 'rating' | '-rating';
type RecipeTypeOptions = Recipe['recipeType'];

const RecipeListScreen: React.FC = () => {
  const dispatch = useDispatch();

  const recipes = useSelector((state: GlobalState) => state.recipe.list);

  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState<SelectOptions>('-rating');
  const [recipeType, setRecipeType] = useState<RecipeTypeOptions>('default');
  const [nextPage, setNextPage] = useState<number | null>(null);
  const searchText = useRef<string>('');

  const loadRecipes = useCallback(
    async (search?: string) => {
      setIsLoading(true);

      const response = await dispatch(listRecipes(1, sorting, recipeType, search));

      setIsLoading(false);

      if (response.error) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Could not fetch recipes',
          isClosable: true,
        });
        return;
      }

      const { payload } = response as ListRecipesSuccess;

      setNextPage(payload.next ? 2 : null);
    },
    [sorting, recipeType, searchText],
  );

  useEffect(() => {
    loadRecipes();
  }, []);

  const recipeList = orderBy(
    Object.values(recipes),
    ({ averageRating }) => averageRating || (sorting === '-rating' ? -1 : 6),
    [sorting === '-rating' ? 'desc' : 'asc'],
  );

  const handleOnSortingChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSorting(e.target.value as SelectOptions);
    dispatch({
      type: actionTypes.LIST_RECIPES_CLEAR,
    });
  };

  const handleOnRecipeTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRecipeType(e.target.value as RecipeTypeOptions);
    dispatch({
      type: actionTypes.LIST_RECIPES_CLEAR,
    });
  };

  const loadMore = async () => {
    if (!nextPage) {
      return;
    }
    const response = await dispatch(listRecipes(nextPage, sorting, recipeType, searchText.current));
    if (response.error) {
      toast({
        title: 'Error',
        description: 'An error occurred whilst loading more recipes.',
        status: 'error',
      });
    } else {
      const { payload } = response as ListRecipesSuccess;
      setNextPage(payload.next ? nextPage + 1 : null);
    }
  };

  const handleSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    searchText.current = event.target.value;

    dispatch({
      type: actionTypes.LIST_RECIPES_CLEAR,
    });
    loadRecipes(event.target.value);
  }, 250);

  return (
    <ScreenWrapper>
      <Stack flex={1} padding="xl">
        <HStack spacing="md" align="flex-end" justifyContent="space-between">
          <VStack spacing="xs" alignItems="flex-start">
            <Text fontWeight="bold">Change order of recipes</Text>
            <Select placeholder="Select Option" value={sorting} onChange={handleOnSortingChange}>
              <option value={'-rating'}>Sort By Highest Rating</option>
              <option value={'rating'}>Sort By Lowest Rating</option>
            </Select>
          </VStack>
          <Flex width="50%">
            <Search
              value=""
              isLoading={false}
              onSearchChange={handleSearch}
              resultRenderer={result => <Fragment />}
              onResultSelect={() => {}}
              shouldShowResults={false}
            />
          </Flex>
          <VStack spacing="xs" alignItems="flex-start">
            <Text fontWeight="bold">Recipe type</Text>
            <Select
              placeholder="Select Option"
              value={recipeType}
              onChange={handleOnRecipeTypeChange}
            >
              <option value={'default'}>Default</option>
              <option value={'youtube'}>YouTube</option>
              <option value={'standalone'}>Standalone</option>
            </Select>
          </VStack>
        </HStack>

        {isLoading ? (
          <Flex flex={1} minW="3xl" flexDirection="column" minH="100vh">
            <Stack>
              {Array.from(Array(5)).map((_, idx) => (
                <Fragment key={idx}>
                  <Skeleton height="60px" />
                </Fragment>
              ))}
            </Stack>
          </Flex>
        ) : (
          <InfiniteScroll
            dataLength={recipeList.length}
            hasMore={nextPage !== null}
            loader={
              <Flex alignItems="center" justifyContent="center" py="sm">
                <Spinner />
                <Text ml="sm">Loading more...</Text>
              </Flex>
            }
            next={loadMore}
            endMessage={
              <Flex alignItems="center" justifyContent="center" py="sm">
                <Text textAlign="center" fontWeight="bold" fontSize="lg">
                  Yay! You&apos;ve seen it all
                </Text>
              </Flex>
            }
          >
            {recipeList.map(recipe => (
              <LinkBox
                key={recipe.id}
                role="group"
                display="flex"
                flex={1}
                bgColor="gray.500"
                color="white"
                padding="md"
                cursor="pointer"
                borderRadius="md"
                mb={2}
              >
                <LinkOverlay
                  as={Link}
                  fontWeight="bold"
                  to={`${routes.recipesList}/${recipe.id}`}
                  flex={1}
                >
                  <Text>
                    {recipe.code} - {recipe.title}
                  </Text>
                </LinkOverlay>
                {recipe.averageRating ? (
                  <HStack>
                    <Text>{recipe.averageRating}</Text>
                    <ImStarFull color="yellow" fontSize={24} />
                  </HStack>
                ) : null}
              </LinkBox>
            ))}
          </InfiniteScroll>
        )}
      </Stack>
    </ScreenWrapper>
  );
};

export default RecipeListScreen;
