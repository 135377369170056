import React from "react";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  CloseButton,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text?: string;
  isLoading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmColor?: string;
  cancelColor?: string;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  text = "Are you sure?",
  isLoading,
  confirmLabel = "Yes",
  cancelLabel = "No",
  confirmColor = "red",
  cancelColor = "blue"
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between">
            {text}
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalFooter>
          <Button colorScheme={confirmColor} onClick={onConfirm} isLoading={isLoading}>
            {confirmLabel}
          </Button>
          <Button ml="xs" colorScheme={cancelColor} onClick={onClose}>
            {cancelLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
