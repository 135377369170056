export const getCookie = (cname: string) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  let cookieValue = "";
  ca.forEach((cookie: string) => {
    if (cookie.split("=")[0].trim() === cname) {
      cookieValue = cookie.split("=")[1];
    }
  });
  return cookieValue;
};

export const clearCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};
