import { IngredientTypes, Pack } from 'types';
import { SummaryPack } from 'types/pack';

export enum DietaryTag {
  meat = 'M',
  fish = 'F',
  meatAndFish = 'M&F',
  veg = 'V',
  vegan = 'VE',
}

export type NutritionalTag = 'NF' | 'GF' | 'EF' | 'DF' | 'VE' | 'V' | 'M' | 'F';

export interface Tag {
  id: number;
  name: string;
  slug: string;
}

export type TagsResponse = Tag[];

interface Category {
  id: number;
  name: string;
  shoppingListDisplay: string;
}

interface IngredientType {
  id: number;
  name: IngredientTypes;
}

export interface Ingredient {
  category?: Category;
  cost?: number;
  id: number;
  name: string;
  quantity?: number;
  quantityImperial?: number;
  tags?: Array<Tag>;
  type: IngredientType | null;
  uid: string;
  unit: number;
  unitImperial?: Unit;
}

export interface Unit {
  id: number;
  name: string;
  nameAbbrev: string;
  pluralAbbrev: string;
  type: 'mass' | 'volume' | 'other';
  system: 'si' | 'imperial' | null;
}

export interface ObjectKeys {
  [key: string]: string | number | Pack[] | SummaryPack[] | boolean;
}

export type AltImages = {
  fullSize: string;
  small: string;
  thumbnail: string;
  medium: string;
};

export enum ContentType {
  recipe = 'recipe',
  pack = 'recipepack',
}

export type Rating = {
  id: number;
  count: number;
  total: number;
  average: string;
  targetContentType: ContentType;
  targetObjectUid: string;
} | null;

export type Collaborator = {
  uid: string;
  name: string;
  active: boolean;
  icon: string;
  iconBlurHash: string;
};

export type ShoppingListItem = {
  ingredienType: string;
  metricAmount: number;
  metricMeasurement: string;
  imperialAmount: number;
  imperialMeasurement: string;
  ingredientName: string;
  prepInstructions?: string | null;
};

// Unique Ingredient type defined on our backend
export const EQUIPMENT_TYPE_NAME = 'Equipment';
// Unique Checklist type defined on our backend
export const EQUIPMENT_CHECKLIST_TYPE = 'equipment';
