import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import groupBy from 'lodash/groupBy';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { spoonTypes } from 'constants/data';
import { useForm } from 'hooks';
import { toast } from 'navigation/AppRouter';

import { RecipeIngredientFiltered } from 'screens/Recipes/RecipeScreen';

import LabelInput from 'components/LabelInput';
import Prompt from 'components/Prompt';

import { AiIcon, BsIcon } from 'theme/icon';
import { GlobalState, Ingredient } from 'types';

interface OptionType {
  label: string;
  value: number;
}

export interface IngredientFormRow extends Omit<RecipeIngredientFiltered, 'ingredient'> {
  // useFieldArray overwrites the "id" value with a uuid so we allocate the recipe ingredient
  // id to its own key
  recipeIngredientId: number | null | undefined;
  ingredient: { label: string; value: number } | undefined;
  unit: { label: string; value: number } | undefined;
  disableScaling: boolean;
}

/**
 * For the given ingredient, filter the list of unit options to only return
 * valid unit options for this ingredient
 */
const filterUnitOptions = (ingredient: Ingredient | undefined, unitOptions: OptionType[]) => {
  if (ingredient == null) return unitOptions;

  const unitsFromIngredient = unitOptions.filter(option => option.value === ingredient.unit);

  // If the unit is "g" or "ml" we can allow
  // "tsp" and "tbsp" as part of the available units, but only if the
  // ingredient type is a valid 'spoon type'
  const unitMap = unitsFromIngredient.map(unit => unit.label);

  if (
    ingredient.type?.name &&
    spoonTypes.includes(ingredient.type.name) &&
    (unitMap.includes('g') || unitMap.includes('ml'))
  ) {
    const tspUnit = unitOptions.filter(o => o.label === 'tsp');
    const tbspUnit = unitOptions.filter(o => o.label === 'tbsp');

    unitsFromIngredient.push(...tspUnit, ...tbspUnit);
  }

  return unitsFromIngredient;
};

interface FormState {
  recipeIngredients: {
    name: string;
    nestedArray: IngredientFormRow[];
  }[];
}

type RecipeIngredientGroupFormProps = UseFormReturn<FormState> & {
  unitOptions: OptionType[];
  ingredientOptions: OptionType[];
  group: string | undefined;
  index: number;
};

const RecipeIngredientGroupForm: React.FC<RecipeIngredientGroupFormProps> = ({
  unitOptions,
  ingredientOptions,
  group,
  index,
  ...useFormValues
}) => {
  const [groupName, setGroupName] = useState<string>(group || '');

  const { register, control, formState, watch } = useFormValues;

  const ingredients = useSelector((state: GlobalState) => state.common.ingredients);

  const getFormName = () => `recipeIngredients.${index}.nestedArray` as const;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: getFormName(),
  });

  const { errors } = formState;

  const watchRecipeIngredients = watch(`recipeIngredients.${index}` as const);

  return (
    <Box bg="green.100" borderRadius="md" padding="sm" mb="4">
      <LabelInput
        type="text"
        defaultValue={groupName}
        value={groupName}
        onChange={e => {
          setGroupName(e.target.value);
          fields.forEach((field, idx) => update(idx, { ...field, group: e.target.value }));
        }}
        backgroundColor="white"
        mb="4"
        name="group"
        label="Group"
      />
      <Table flex={1} size="sm">
        <Thead flex={1}>
          <Tr>
            <Td fontWeight="bold">Index</Td>
            <Td fontWeight="bold">Metric Amount</Td>
            <Td fontWeight="bold">Ingredient Name</Td>
            <Td fontWeight="bold">Metric Measurement</Td>
            <Td fontWeight="bold">Prep Instructions</Td>
            <Td fontWeight="bold">Disable Scaling</Td>
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((item, ingIndex) => {
            // From the `watchedRecipeIngredients` extract the ingredient value that's been selected in this row
            const watchIngredient =
              watchRecipeIngredients?.nestedArray[ingIndex]?.ingredient?.value;

            // If an ingredient has been selected, extract our base `Ingredient` type using the ingredient ID
            const selectedIngredient = watchIngredient ? ingredients[watchIngredient] : undefined;

            // If an ingredient has been selected, filter our list of unit options to
            // ensure we're only showing valid units for this ingredient
            const filteredUnitOptions = filterUnitOptions(selectedIngredient, unitOptions);

            const rowErrors = errors?.recipeIngredients?.[index]?.nestedArray?.[ingIndex] || null;

            const itemIdx = item.index || fields.length;

            return (
              <Tr
                key={item.id}
                css={`
                  td:first-of-type {
                    padding-left: 0;
                  }
                  td:last-child {
                    padding-right: 0;
                  }
                `}
              >
                <Td maxWidth={20}>
                  {/* Manually set default values as hidden inputs */}
                  <Input
                    type="hidden"
                    {...register(`${getFormName()}.${ingIndex}.recipeIngredientId` as const)}
                    value={item.recipeIngredientId || undefined}
                  />
                  <Input
                    type="hidden"
                    {...register(`${getFormName()}.${ingIndex}.group` as const)}
                    value={groupName}
                  />
                  {item.metric?.id && (
                    <Input
                      type="hidden"
                      {...register(`${getFormName()}.${ingIndex}.metric.id` as const)}
                      value={item.metric.id}
                    />
                  )}
                  <Input
                    {...register(`${getFormName()}.${ingIndex}.index` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    type="text"
                    placeholder="Index"
                    bgColor="white"
                    defaultValue={itemIdx}
                  />
                </Td>
                <Td maxWidth={20}>
                  <Input
                    {...register(`${getFormName()}.${ingIndex}.metric.quantity` as const, {
                      required: true,
                      valueAsNumber: true,
                      min: 0.1,
                    })}
                    type="text"
                    placeholder="Metric Amount"
                    bgColor="white"
                    defaultValue={item.metric?.quantity || 0}
                  />
                </Td>
                <Td>
                  <FormControl isInvalid={!!rowErrors?.ingredient || false}>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => {
                        const itemValue = ingredientOptions.find(o => o.value === value?.value);
                        return (
                          <Select
                            options={ingredientOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            onChange={e => onChange(e)}
                            onBlur={onBlur}
                            value={itemValue}
                          />
                        );
                      }}
                      name={`${getFormName()}.${ingIndex}.ingredient` as const}
                      control={control}
                      defaultValue={item.ingredient}
                      rules={{ required: 'Ingredient is required' }}
                    />
                    <FormErrorMessage>{rowErrors?.ingredient?.message || null}</FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={!!rowErrors?.unit || false}>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => {
                        const itemValue = filteredUnitOptions.find(o => o.value === value?.value);
                        return (
                          <Select
                            options={filteredUnitOptions}
                            onChange={e => onChange(e)}
                            onBlur={onBlur}
                            value={itemValue}
                          />
                        );
                      }}
                      name={`${getFormName()}.${ingIndex}.unit` as const}
                      control={control}
                      defaultValue={item.unit}
                      rules={{ required: 'Unit is required' }}
                    />
                    <FormErrorMessage>{rowErrors?.unit?.message || null}</FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <Input
                    {...register(`${getFormName()}.${ingIndex}.metric.prepInstructions` as const)}
                    type="text"
                    placeholder="Prep Instructions"
                    bgColor="white"
                    defaultValue={item.metric?.prepInstructions}
                  />
                </Td>
                <Td>
                  <FormControl my="xs">
                    <Checkbox
                      {...register(`${getFormName()}.${ingIndex}.disableScaling` as const)}
                      defaultChecked={item.disableScaling}
                    />
                  </FormControl>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex flex={1} alignItems="center" justifyContent="space-between" paddingTop="md">
        <HStack flex={1}>
          <>
            <Button
              onClick={() =>
                append({
                  group: groupName,
                  index: (fields[0].index || 1) + fields.length,
                })
              }
              leftIcon={<BsIcon name="PlusSquareFill" color="blue.500" />}
              variant="ghost"
            >
              Add row
            </Button>
            {fields.length ? (
              <Button
                onClick={() => remove(fields.length - 1)}
                leftIcon={<AiIcon name="FillMinusSquare" color="red.500" />}
                variant="ghost"
              >
                Remove row
              </Button>
            ) : null}
          </>
        </HStack>
      </Flex>
    </Box>
  );
};

interface RecipeIngredientFormProps {
  unitOptions: OptionType[];
  ingredientOptions: OptionType[];
  /**
   * Default values for recipeIngredients
   */
  recipeIngredients: RecipeIngredientFiltered[];
  title: string;
  onSubmit: (values: IngredientFormRow[]) => Promise<boolean>;
  isSubmitting: boolean;
  isLoading: boolean;
  /**
   * Force react-hook-form to reset displayed data if this changes
   */
  portionSize: number;
}

const RecipeIngredientForm: React.FC<RecipeIngredientFormProps> = props => {
  const {
    portionSize,
    unitOptions,
    ingredientOptions,
    recipeIngredients,
    title,
    onSubmit,
    isSubmitting,
    isLoading,
  } = props;

  const defaultValues: IngredientFormRow[] = recipeIngredients.map(recipeIng => {
    // Rebuild the default values to account for the
    // react-select components
    const defaultUnit = unitOptions.find(option => option.value === recipeIng?.metric?.unit?.id);
    const defaultIngredient = ingredientOptions.find(
      option => option.value === recipeIng?.ingredient,
    );

    return {
      ...recipeIng,
      recipeIngredientId: recipeIng.id,
      unit: defaultUnit,
      ingredient: defaultIngredient,
      group: recipeIng.group || '',
      disableScaling: recipeIng.disableScaling || false,
    };
  });

  console.log('recipeIngredients >>> ', recipeIngredients);

  const groupedRecipeIngredients = groupBy(defaultValues, 'group');
  const formDefault = Object.keys(groupedRecipeIngredients).reduce((acc, key) => {
    return [...acc, { name: key, nestedArray: groupedRecipeIngredients[key] }];
  }, [] as { name: string; nestedArray: IngredientFormRow[] }[]);

  const useFormValues = useForm({ defaultValues: { recipeIngredients: formDefault } });
  const { control, handleSubmit, formState, reset, unregister } = useFormValues;

  const { fields, append } = useFieldArray({
    control,
    name: 'recipeIngredients',
  });

  const { isDirty } = formState;

  const resetForm = async () => {
    /**
     * Reset the form whenever the form has been submitted (i.e. the
     * RecipeIngredient prop has changed and the component is not in
     * an isSubmitting state). This is likely to happen on form submission
     * when we need to update the rows
     */
    if (!isSubmitting) {
      await unregister('recipeIngredients');
      reset({
        recipeIngredients: formDefault,
      });
    }
  };

  useEffect(() => {
    resetForm();
  }, [isSubmitting]);

  useEffect(() => {
    /**
     * useFieldArray hook is uncontrolled (as it allows dynamic inputs)
     * the "fields" prop does not update on rerender therefore we manually
     * reset the form on portionSize change
     */

    reset({
      recipeIngredients: formDefault,
    });
  }, [portionSize, reset]);

  const onSubmitData = handleSubmit(async values => {
    // Check whether there are any duplicate indexes
    const indexList = values.recipeIngredients.flatMap(ing => ing.nestedArray.map(i => i.index));

    if (new Set(indexList).size !== indexList.length) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Duplicate recipe indexes found. Please ensure indexes are unique.',
        isClosable: true,
      });
    } else {
      await onSubmit(values.recipeIngredients.flatMap(ing => ing.nestedArray));
    }
  });

  return (
    <>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      <Heading as="h3" size="lg" mb="sm">
        {title}
      </Heading>
      <form onSubmit={onSubmitData}>
        {fields.map((item, idx) => {
          return (
            <RecipeIngredientGroupForm
              {...props}
              {...useFormValues}
              key={item.id}
              group={item.name}
              index={idx}
            />
          );
        })}
        <HStack flex={1} alignItems="center" justifyContent="space-between">
          <Button
            onClick={() => {
              // Find the last item in the last row (group) and get the index. When adding
              // a new group, we add 1 to this value
              let index = 1;
              const row = fields[fields.length - 1];
              if (row) {
                const lastRowItem = row.nestedArray[row.nestedArray.length - 1];
                index = (lastRowItem.index || 0) + 1;
              }
              // @ts-ignore
              append({ name: '', nestedArray: [{ index }] });
            }}
            leftIcon={<BsIcon name="PlusSquareFill" color="blue.500" />}
            variant="ghost"
          >
            Add group
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isLoading || !isDirty}
          >
            Save
          </Button>
        </HStack>
      </form>
    </>
  );
};

export default RecipeIngredientForm;
