import actionTypes from "redux/actionTypes";
import { callAPI } from "utils";
import API from "constants/api";

import { FetchTokenAction, LogoutAction, VerifyTokenAction } from "types";

export const fetchToken = (data: { email: string; password: string }) => {
  return callAPI<FetchTokenAction>({
    types: [
      actionTypes.FETCH_TOKEN_REQUEST,
      actionTypes.FETCH_TOKEN_SUCCESS,
      actionTypes.FETCH_TOKEN_FAILURE,
    ],
    endpoint: API.login,
    method: "POST",
    body: data,
    headers: {
      "Allow-Set-Auth-Cookie": "True",
    },
  });
};

export const logout = () => {
  return callAPI<LogoutAction>({
    types: [
      actionTypes.LOGOUT_REQUEST,
      actionTypes.LOGOUT_SUCCESS,
      actionTypes.LOGOUT_FAILURE,
    ],
    endpoint: API.logout,
    method: "POST",
    headers: {
      "Allow-Set-Auth-Cookie": "True",
    },
  });
};

export const verifyToken = () => {
  return callAPI<VerifyTokenAction>({
    types: [
      actionTypes.VERIFY_TOKEN_REQUEST,
      actionTypes.VERIFY_TOKEN_SUCCESS,
      actionTypes.VERIFY_TOKEN_FAILURE,
    ],
    endpoint: API.verifyToken,
    method: "POST",
  });
};
