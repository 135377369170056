import { Flex, Spinner, Text } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'hooks';
import { verifyToken } from 'redux/actions/auth';
import {
  listAttributes,
  listCuisine,
  listIngredients,
  listThemes,
  listUnits,
} from 'redux/actions/common';
import { TwistsScreen } from '../screens/Twists';

import Navbar from 'components/Navbar';
import PrivateRoute from './PrivateRoute';

import HomeContainer from 'screens/HomeContainer';
import IngredientScreen from 'screens/Ingredients/IngredientScreen';
import LoginContainer from 'screens/LoginContainer';
import { MealPackScreen, MealPacksListScreen } from 'screens/MealPacks';
import RecipeListScreen from 'screens/Recipes/RecipeListScreen';
import RecipeScreen from 'screens/Recipes/RecipeScreen';

import { GlobalState } from 'types';

import routes from './routes';

const { ToastContainer, toast } = createStandaloneToast();

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ScrollToTop: React.FC<any> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

const AppRouter: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector((state: GlobalState) => state.auth.isAuthenticated);

  useEffect(() => {
    /**
     * The purpose of this effect is to set the isLoading value to
     * true while we fetch the necessary data for the app to function
     * properly, e.g, the tags, ingredients and units
     *
     * The effect triggers on intial load and the data will be fetched
     * as long as the token is valid.
     *
     * The effect will also trigger after logging in,
     * hence the dependency on isAuthenticated from the global state.
     */

    if (!isAuthenticated) return;

    const initAuth = async () => {
      const verifyResponse = await dispatch(verifyToken());

      if (verifyResponse.error) {
        // If token verification has failed navigate back to the login pack
        navigate(routes.login);
        setIsLoading(false);

        return;
      }

      // Run data fetching APIs
      await dispatch(listThemes());
      await dispatch(listAttributes());
      await dispatch(listCuisine());
      await dispatch(listIngredients());
      await dispatch(listUnits());

      setIsLoading(false);
    };

    if (!isLoading) setIsLoading(true);
    initAuth();
  }, [isAuthenticated, setIsLoading, dispatch]);

  return isLoading ? (
    <Flex
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      opacity={0.75}
      bgColor="white"
      position="absolute"
      zIndex={999}
    >
      <Spinner />
      <Text ml="sm">Loading</Text>
    </Flex>
  ) : (
    <>
      <Navbar />
      <SentryRoutes>
        <Route path={routes.login} element={<LoginContainer />} />
        <Route
          path={routes.base}
          element={
            <PrivateRoute>
              <HomeContainer />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.packList}
          element={
            <PrivateRoute>
              <MealPacksListScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.packs}
          element={
            <PrivateRoute>
              <MealPackScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.recipesList}
          element={
            <PrivateRoute>
              <RecipeListScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.recipes}
          element={
            <PrivateRoute>
              <RecipeScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.ingredients}
          element={
            <PrivateRoute>
              <IngredientScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.twists}
          element={
            <PrivateRoute>
              <TwistsScreen />
            </PrivateRoute>
          }
        />
      </SentryRoutes>
      <ScrollToTop />
      <ToastContainer />
    </>
  );
};

export { toast };

export default AppRouter;
