const routes = {
  base: "/",
  login: "/login",

  packList: "/packs",
  packs: "/packs/:id",

  recipesList: "/recipes",
  recipes: "/recipes/:id",

  ingredients: "/ingredients",

  twists: "/twists",
};

export default routes;
