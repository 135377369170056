import {
  Button,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useForm } from 'hooks';

import FallbackImg from 'components/FallbackImg';
import LabelCalculation from 'components/LabelCalculation';
import LabelInput from 'components/LabelInput';
import LabelSelect from 'components/LabelSelect';
import LabelTextArea from 'components/LabelTextArea';
import Prompt from 'components/Prompt';

import { buildLabelCalculationProps } from 'screens/Recipes/Forms/RecipeMethodForm';

import { HiIcon } from 'theme/icon';
import { BasePack, Pack } from 'types';

interface Props {
  pack: Pack | BasePack;
  itemCounts: {
    primaryIngredients: number;
    primaryEquipment: number;
  };
  onSubmit: (values: Partial<Pack>) => Promise<void>;
  isLoading?: boolean;
  isSubmitting?: boolean;
  isGeneratingShoppingList?: boolean;
  shoppingListExists?: boolean;
  handleGenerateShoppingList?: () => Promise<void>;
}

const PacksInformationForm: React.FC<Props> = ({
  pack,
  itemCounts,
  onSubmit,

  isLoading = false,
  isSubmitting = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const defaultValues = {
    code: pack.code,
    name: pack.name,
    status: pack.status,
    story: pack.story,
  };

  const { register, handleSubmit, formState, reset } = useForm<Partial<Pack>>({
    defaultValues,
  });

  useEffect(() => {
    /**
     * If the pack has changed ensure we reset the form to avoid
     * using stale pack data (e.g. navigating between packs on a pack page)
     */
    reset(defaultValues);
  }, [pack]);

  const { isDirty, errors } = formState;

  const onSubmitData = handleSubmit(async data => {
    await onSubmit(data);
    reset(data);
  });

  return (
    <>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      <form onSubmit={onSubmitData}>
        <Heading as="h3" size="lg" mb="sm">
          Pack Details
        </Heading>
        <HStack spacing="md" align="stretch">
          <VStack flex={1} spacing="xs" alignItems="flex-start">
            <Text fontWeight="bold">Pack Information</Text>
            <LabelSelect
              name="status"
              label="Pack Status"
              register={register}
              options={[
                { value: 'do_not_use', label: 'Do Not Use' },
                { value: 'draft', label: 'Written Draft' },
                { value: 'labbed', label: 'Labbed & Amended' },
                { value: 'photos_uploaded', label: 'Photos Uploaded' },
                { value: 'ready_for_scripts', label: 'Ready For Scripts' },
                { value: 'audios_uploaded', label: 'Audios Uploaded' },
                { value: 'audios_proofed', label: 'Assets Proofed' },
                { value: 'published', label: 'Published' },
              ]}
            />
            <LabelInput placeholder="Code" name="code" register={register} label="Pack Code" />
            <LabelInput placeholder="Name" name="name" register={register} label="Pack Name" />
            <LabelCalculation
              cursor="pointer"
              fontSize="sm"
              px="sm"
              label="Combined Pack Photo"
              value={`${pack.code}_P1.jpg`}
              {...buildLabelCalculationProps(pack.photo)}
              onClick={() => (pack.photo ? setIsOpen(true) : null)}
            />
            <LabelTextArea
              placeholder="Pack Story"
              name="story"
              register={register}
              registerOptions={{ maxLength: 340 }}
              label="Pack Story"
              helpText="Maximum of 340 characters"
              errorText={
                errors.story && errors.story.type === 'maxLength'
                  ? 'Max length exceeded'
                  : undefined
              }
              isInvalid={Boolean(errors.story)}
            />
          </VStack>
          <VStack flex={1} spacing="xs" alignItems="flex-start">
            {/* Double check that we're dealing with Pack type instead BasePack */}
            {'freshIngredientCost' in pack && (
              <VStack pt="lg">
                <LabelCalculation
                  label="Fresh Ingredient Cost"
                  value={`£ ${pack.freshIngredientCost}`}
                  status={pack.budget ? 'success' : 'warning'}
                  tooltipText="Budget if < £12"
                />
                <LabelCalculation
                  label="Store Cupboard Ingredient Cost"
                  value={`£ ${pack.storeCupboardIngredientCost}`}
                />
              </VStack>
            )}
          </VStack>
          <VStack spacing="xs" align="flex-start">
            <Text fontWeight="bold">Total</Text>
            <LabelCalculation
              label="Total Ingredients in Recipe Pack"
              value={itemCounts.primaryIngredients}
            />
            <LabelCalculation
              label="Total Equipment in Recipe Pack"
              value={itemCounts.primaryEquipment}
            />
          </VStack>
        </HStack>
        <HStack alignItems="flex-end" justifyContent="flex-end" paddingTop="md" spacing="md">
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isLoading || !isDirty}
          >
            Save
          </Button>
        </HStack>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody py={5}>
              {pack.photo ? (
                <Image src={pack.photo} borderRadius="sm" fallback={<FallbackImg />} />
              ) : (
                <VStack>
                  <HiIcon
                    name="OutlineExclamationCircle"
                    color="yellow.400"
                    fontSize={24}
                    cursor="pointer"
                  />
                  <Text>Image has not been uploaded yet for pack {pack.code}</Text>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </>
  );
};

export default PacksInformationForm;
