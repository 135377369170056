import React from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  Flex,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import LabelInput from 'components/LabelInput';
import { Pack, Recipe } from 'types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: Partial<Pack | Recipe>) => Promise<void>;
  isSubmitting: boolean;
  isRecipe?: boolean;
}

const CreateRecipePackModal: React.FC<Props> = ({
  isOpen,
  onClose,
  isSubmitting,
  onSubmit,
  isRecipe = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Partial<Pack | Recipe>>();

  const label = isRecipe ? 'Recipe Code' : 'Pack Code';
  const buttonLabel = isRecipe ? 'Create Recipe' : 'Create Pack';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{isRecipe ? 'Create a Recipe Pack' : 'Create a Recipe'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column">
              <LabelInput
                placeholder={label}
                name="code"
                register={register}
                registerOptions={{
                  required: true,
                }}
                label={label}
                errorText={
                  errors.code ? `Please enter a ${isRecipe ? 'recipe' : 'pack'} code` : undefined
                }
                isInvalid={Boolean(errors.code)}
                isRequired
              />
              <Box mt={2}>
                {isRecipe && (
                  <LabelInput
                    placeholder="Recipe Title"
                    name="title"
                    register={register}
                    registerOptions={{
                      required: true,
                    }}
                    label="Recipe Title"
                    errorText={errors.code ? 'Please enter a recipe title' : undefined}
                    // @ts-ignore
                    isInvalid={Boolean(errors.title)}
                    isRequired
                  />
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={!isDirty || isSubmitting}
                colorScheme="blue"
              >
                {buttonLabel}
              </Button>
              <Button onClick={onClose} isDisabled={isSubmitting}>
                Close
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateRecipePackModal;
