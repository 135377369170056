/**
 * The maximum pack tags allowed for each Meal Pack
 */
export const MAX_PACK_TAGS_PER_PACK = 4;

/**
 * The maximum theme tags allowed for each Meal Pack
 */
export const MAX_PACK_THEMES_PER_PACK = 1;

/**
 * The maximum dietary tags allowed for each Meal Pack
 */
export const MAX_DIETARY_TAGS_PER_PACK = 1;

export const SEASONS = ["spring", "summer", "autumn", "winter"];

export const CALCULATED_ATTRIBUTES = ['<8 ingredients', 'Speedy']

/**
 * Units
 */
export const metricUnits = ["g", "ml"];
export const otherUnits = [
  "clove",
  "ea",
  "head",
  "loaf",
  "nest",
  "pinch",
  "rasher",
  "sheet",
  "stick",
  "tbsp",
  "tsp",
];

/**
 * Meal Pack Portions
 */
export const maxPortions = 6;

/**
 * Ingredient Types
 */
export const spoonTypes = [
  "Alcohol",
  "Tins / Jars / Bottles",
  "Fruit / Veg / Herbs",
  "Staple Carbs / Grains",
  "Non-food",
  "Nuts / Seeds / Dried Fruit",
  "Seasonings",
];

/**
 * Tags
 */
export const ingredientTags = ["V", "VE", "GF", "DF", "NF", "EF"];
