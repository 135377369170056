import actionTypes from "redux/actionTypes";
import { callAPI } from "utils";
import API from "constants/api";

import {
  AssetUploadAction,
  ListUnitsAction,
  ListIngredientsAction,
  ListAttributeTagsAction,
  ListPackThemesAction,
  ListCuisineTagsAction
} from "types";

export const listThemes = () => {
  return callAPI<ListPackThemesAction>({
    types: [
      actionTypes.LIST_PACK_THEMES_REQUEST,
      actionTypes.LIST_PACK_THEMES_SUCCESS,
      actionTypes.LIST_PACK_THEMES_FAILURE,
    ],
    endpoint: API.themes,
    method: "GET",
  });
};

export const listCuisine = () => {
  return callAPI<ListCuisineTagsAction>({
    types: [
      actionTypes.LIST_CUISINE_TAGS_REQUEST,
      actionTypes.LIST_CUISINE_TAGS_SUCCESS,
      actionTypes.LIST_CUISINE_TAGS_FAILURE,
    ],
    endpoint: API.cuisine,
    method: "GET",
  });
};

export const listAttributes = () => {
  return callAPI<ListAttributeTagsAction>({
    types: [
      actionTypes.LIST_ATTRIBUTE_TAGS_REQUEST,
      actionTypes.LIST_ATTRIBUTE_TAGS_SUCCESS,
      actionTypes.LIST_ATTRIBUTE_TAGS_FAILURE,
    ],
    endpoint: API.attributes,
    method: "GET",
  });
};

export const listIngredients = () => {
  return callAPI<ListIngredientsAction>({
    types: [
      actionTypes.LIST_INGREDIENTS_REQUEST,
      actionTypes.LIST_INGREDIENTS_SUCCESS,
      actionTypes.LIST_INGREDIENTS_FAILURE,
    ],
    endpoint: API.ingredients,
    method: "GET",
  });
};

export const listUnits = () => {
  return callAPI<ListUnitsAction>({
    types: [
      actionTypes.LIST_UNITS_REQUEST,
      actionTypes.LIST_UNITS_SUCCESS,
      actionTypes.LIST_UNITS_FAILURE,
    ],
    endpoint: API.units,
    method: "GET",
  });
};

export const assetUpload = (files: FormData) => {
  return callAPI<AssetUploadAction>({
    types: [
      actionTypes.ASSET_UPLOAD_REQUEST,
      actionTypes.ASSET_UPLOAD_SUCCESS,
      actionTypes.ASSET_UPLOAD_FAILURE,
    ],
    endpoint: API.assetUpload,
    method: "POST",
    body: files,
  });
};
