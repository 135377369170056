import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import * as Icon from 'react-icons/bs';
import { IconType } from 'react-icons';

/**
 * Loop through all of the Icons from react-icons/bs and
 * wrap them in our Chakra Box. With this we can use our
 * theme values as inputs/props to the icons for layout and
 * styling
 */

export type BsIconProps = BoxProps & {
  name: string;
};

const BsIcon: React.FC<BsIconProps> = ({ name, ...props }) => {
  return <Box as={WrappedIcon[name]} {...props} />;
};

const WrappedIcon = Object.keys(Icon).reduce(
  (
    acc: {
      [key: string]: IconType;
    },
    key
  ) => {
    // @ts-ignore - can't index an imported module
    const icon: IconType = Icon[key];
    // Remove the prepended 'Md' from all Icon names
    const cleanKey = key.replace('Bs', '');
    // Add the modified Icon ready for export
    acc[cleanKey] = icon;
    return acc;
  },
  {}
);

export default BsIcon;
