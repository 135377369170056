import API from 'constants/api';
import { decamelizeKeys } from 'humps';
import actionTypes from 'redux/actionTypes';
import { callAPI } from 'utils';

import {
  CreatePackAction,
  DuplicatePackAction,
  ListPackRecipesAction,
  ListPacksAction,
  ListSummaryPacksAction,
  Pack,
  RemovePackRecipeAction,
  RetrievePackAction,
} from 'types';

export const listPacks = (page: string | null = null, filter?: string, search?: string) => {
  return callAPI<ListPacksAction>({
    types: [
      actionTypes.LIST_PACKS_REQUEST,
      actionTypes.LIST_PACKS_SUCCESS,
      actionTypes.LIST_PACKS_FAILURE,
    ],
    endpoint: API.packs(undefined, page, filter, search),
    method: 'GET',
  });
};

export const listSummaryPacks = () => {
  return callAPI<ListSummaryPacksAction>({
    types: [
      actionTypes.LIST_SUMMARY_PACKS_REQUEST,
      actionTypes.LIST_SUMMARY_PACKS_SUCCESS,
      actionTypes.LIST_SUMMARY_PACKS_FAILURE,
    ],
    endpoint: API.searchPacks,
    method: 'GET',
  });
};

export const createPack = (data: Partial<Pack>) => {
  return callAPI<CreatePackAction>({
    types: [
      actionTypes.CREATE_PACK_REQUEST,
      actionTypes.CREATE_PACK_SUCCESS,
      actionTypes.CREATE_PACK_FAILURE,
    ],
    endpoint: API.packs(),
    method: 'POST',
    body: decamelizeKeys(data),
  });
};

export const retrievePack = (packId: number) => {
  return callAPI<RetrievePackAction>({
    types: [
      actionTypes.RETRIEVE_PACK_REQUEST,
      actionTypes.RETRIEVE_PACK_SUCCESS,
      actionTypes.RETRIEVE_PACK_FAILURE,
    ],
    endpoint: API.packs(packId),
    method: 'GET',
  });
};

export const updatePack = (packId: number, packData: Partial<Pack>) => {
  return callAPI<RetrievePackAction>({
    types: [
      actionTypes.UPDATE_PACK_REQUEST,
      actionTypes.UPDATE_PACK_SUCCESS,
      actionTypes.UPDATE_PACK_FAILURE,
    ],
    endpoint: API.packs(packId),
    method: 'PATCH',
    body: decamelizeKeys(packData),
  });
};

export const listPackRecipes = (packId: number) => {
  return callAPI<ListPackRecipesAction>({
    types: [
      actionTypes.LIST_PACK_RECIPES_REQUEST,
      actionTypes.LIST_PACK_RECIPES_SUCCESS,
      actionTypes.LIST_PACK_RECIPES_FAILURE,
    ],
    endpoint: API.packRecipes(packId),
    method: 'GET',
  });
};

export const removePackRecipe = (recipeId: number) => {
  return callAPI<RemovePackRecipeAction>({
    types: [
      actionTypes.REMOVE_PACK_RECIPE_REQUEST,
      actionTypes.REMOVE_PACK_RECIPE_SUCCESS,
      actionTypes.REMOVE_PACK_RECIPE_FAILURE,
    ],
    endpoint: API.packRecipe(recipeId),
    method: 'PATCH',
    body: {
      pack: null, // Setting the pack to null removes the Pack/Recipe relationship
    },
  });
};

export const duplicatePack = (packId: number, data: { newCode: string }) => {
  return callAPI<DuplicatePackAction>({
    types: [
      actionTypes.DUPLICATE_PACK_REQUEST,
      actionTypes.DUPLICATE_PACK_SUCCESS,
      actionTypes.DUPLICATE_PACK_FAILURE,
    ],
    endpoint: API.duplicatePack(packId),
    method: 'POST',
    body: decamelizeKeys(data),
  });
};
