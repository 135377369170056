import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  CloseButton,
  Spinner,
  VStack,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ImBin, ImStarFull } from 'react-icons/im';

import { Recipe } from 'types';
import routes from 'navigation/routes';

import ConfirmationModal from 'components/ConfirmationModal';

interface Props {
  recipes: Recipe[];
  removeRecipe: (recipeId: number) => Promise<any>;
  addRecipe: (data: Partial<Recipe>) => Promise<any>;
  onRecipeClick: (recipeId: number) => void;
  isLoading?: boolean;
  isSubmitting?: boolean;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (arg0: { code: string; title: string }) => Promise<boolean>;
  isLoading: boolean;
}

const PackRecipeModal: React.FC<ModalProps> = ({ isOpen, onClose, onAdd, isLoading }) => {
  const [recipeCode, setRecipeCode] = useState<string | null>(null);
  const [recipeTitle, setRecipeTitle] = useState<string | null>(null);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between">
            Add a Recipe to this Pack
            <CloseButton onClick={onClose} />
          </Flex>{' '}
        </ModalHeader>
        <ModalBody>
          <VStack spacing="sm">
            <Input
              placeholder="Add the Recipe Code"
              onChange={e => setRecipeCode(e.target.value)}
            />
            <Input
              placeholder="Add the Recipe Title"
              onChange={e => setRecipeTitle(e.target.value)}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            onClick={async () => {
              if (!recipeCode || !recipeTitle) return;
              const success = await onAdd({
                code: recipeCode,
                title: recipeTitle,
              });
              // Reset the form
              if (success) {
                setRecipeCode(null);
                setRecipeTitle(null);
              }
            }}
            isDisabled={!recipeCode || isLoading}
            isLoading={isLoading}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const PackRecipesForm: React.FC<Props> = ({
  recipes,
  isLoading = false,
  isSubmitting = false,
  removeRecipe,
  addRecipe,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

  return (
    <>
      <Flex flexDir="column">
        <Heading as="h3" size="lg" mb="sm">
          Pack Recipes
        </Heading>
        <HStack>
          {recipes.map(recipe => (
            <LinkBox
              key={recipe.id}
              role="group"
              display="flex"
              flex={1}
              position="relative"
              bgColor="gray.500"
              color="white"
              padding="sm"
              cursor="pointer"
              minHeight="150px"
              borderRadius="md"
              maxWidth="300px"
            >
              <LinkOverlay as={Link} fontWeight="bold" to={`${routes.recipesList}/${recipe.id}`}>
                <Text>{recipe.title}</Text>
                <Text>({recipe.code})</Text>
                {recipe.averageRating && (
                  <HStack>
                    <Text>{recipe.averageRating}</Text>
                    <ImStarFull color="yellow" fontSize={24} />
                  </HStack>
                )}
              </LinkOverlay>
              <Box
                position="absolute"
                bottom="md"
                right="md"
                opacity={0}
                pointerEvents="none"
                color="red.200"
                _groupHover={{
                  opacity: 1,
                  pointerEvents: 'auto',
                }}
                _hover={{
                  color: 'red.300',
                }}
              >
                {isLoading ? (
                  <Spinner color="red.200" />
                ) : (
                  <ImBin
                    fontSize={24}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedRecipe(recipe);
                      setConfirmationModalIsOpen(true);
                    }}
                  />
                )}
              </Box>
            </LinkBox>
          ))}
          <Flex
            flex={1}
            flexDir="column"
            bgColor="gray.200"
            padding="xs"
            cursor="pointer"
            minHeight="150px"
            maxWidth="300px"
            borderRadius="md"
            justifyContent="flex-start"
            onClick={() => {
              setModalIsOpen(true);
            }}
            fontWeight="bold"
          >
            Add a Recipe +
          </Flex>
        </HStack>
      </Flex>
      <PackRecipeModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onAdd={async data => {
          const response = await addRecipe(data);

          // If the recipe has been successfully added we can close the
          // modal
          !response.error && setModalIsOpen(false);

          return !response.error;
        }}
        isLoading={isLoading || isSubmitting}
      />
      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={() => setConfirmationModalIsOpen(false)}
        onConfirm={() => {
          selectedRecipe &&
            removeRecipe(selectedRecipe.id).then(resp =>
              // If the recipe has been successfully removed we can close
              // the modal
              resp.error ? null : setConfirmationModalIsOpen(false),
            );
        }}
        text="Are you sure you want to remove this Recipe from this Pack?"
        isLoading={isLoading}
      />
    </>
  );
};

export default PackRecipesForm;
