import React, { useState } from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Box,
  Icon,
  BoxProps,
  InputRightElement,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

interface Props extends BoxProps {
  value: string;
  isLoading: boolean;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resultRenderer: (result: any) => JSX.Element;
  onResultSelect: (result: any) => void;
  resultListMaxHeight?: string;
  searchResults?: any[];
  placeholder?: string;
  input?: { iconPosition: 'left' | 'right' };
  noResultFoundText?: string;
  shouldShowResults?: boolean;
}

const Search = (props: Props) => {
  const {
    isLoading,
    input,
    onSearchChange,
    resultRenderer,
    onResultSelect,
    resultListMaxHeight = '60vh',
    placeholder = '',
    searchResults = [],
    noResultFoundText = 'No results found.',
    shouldShowResults = true,
    ...rest
  } = props;

  const { iconPosition = 'left' } = input || {};

  const [value, setValue] = useState('');
  const [showResults, setShowResults] = useState(false);

  const onBlur = () => {
    setTimeout(() => {
      setShowResults(false);
    }, 170);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onSearchChange && onSearchChange(event);
  };

  return (
    <Box position="relative" w="100%" {...rest}>
      <InputGroup>
        {iconPosition === 'left' && (
          <InputLeftElement pointerEvents="none">
            {isLoading ? <Spinner size="sm" /> : <Icon as={FaSearch} />}
          </InputLeftElement>
        )}

        <Input
          borderColor="rgba(34,36,38,.15)"
          borderRadius="full"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onFocus={() => setShowResults(shouldShowResults)}
          onBlur={onBlur}
        />

        {iconPosition === 'right' && (
          <InputRightElement pointerEvents="none">
            {isLoading ? <Spinner size="sm" /> : <Icon as={FaSearch} />}
          </InputRightElement>
        )}
      </InputGroup>
      {showResults && (
        <Box
          bgColor="white"
          maxHeight={resultListMaxHeight}
          overflowY="auto"
          borderRadius="0.3em"
          boxShadow="0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {searchResults.length > 0
            ? searchResults.map(result => (
                <Box
                  key={result.id || result._id || result.key}
                  borderBottom="1px solid rgba(34,36,38,.1)"
                  cursor="pointer"
                  _hover={{
                    bgColor: '#f9fafb',
                  }}
                  onClick={() => onResultSelect(result)}
                >
                  <Flex alignItems="center">
                    <Box p="0.8em" margin="0" color="black">
                      {resultRenderer(result)}
                    </Box>
                  </Flex>
                </Box>
              ))
            : value.length > 0 &&
              !isLoading && (
                <Box borderBottom="1px solid rgba(34,36,38,.1)">
                  <Flex alignItems="center">
                    <Box p="0.8em" margin="0" color="black">
                      <Text>{noResultFoundText}</Text>
                    </Box>
                  </Flex>
                </Box>
              )}
        </Box>
      )}
    </Box>
  );
};

export default Search;
