import humps from 'humps';
import actionTypes from "redux/actionTypes";
import { callAPI } from "utils";
import API from "constants/api";
import {
  CreateRecipeTwistsAction,
  CreateTwistPayload,
  RetrieveTwistsAction,
  UpdateTwistAction
} from 'types';

type ChefTwist = {
  comment: string;
  title: string;
  approved: string;
}

type CommunityTwist = {
  name: string;
  comment: string;
  country: string | null;
  approved: string;
}


export const retrieveTwists = (pageNumber: number, approved?: string) => {
  return callAPI<RetrieveTwistsAction>({
    types: [
      actionTypes.LIST_TWISTS_REQUEST,
      actionTypes.LIST_TWISTS_SUCCESS,
      actionTypes.LIST_TWISTS_FAILURE,
    ],
    endpoint:
      approved !== undefined && approved !== ""
        ? API.twists(pageNumber, approved)
        : API.pendingTwists(pageNumber),
    method: "GET",
  });
};

export const updateTwist = (
  twistId: number,
  data: ChefTwist | CommunityTwist
) => {
  return callAPI<UpdateTwistAction>({
    types: [
      actionTypes.UPDATE_TWIST_REQUEST,
      actionTypes.UPDATE_TWIST_SUCCESS,
      actionTypes.UPDATE_TWIST_FAILURE,
    ],
    endpoint: API.twistUpdate(twistId),
    method: "PATCH",
    body: data,
  });
};

export const createRecipeChefTwist = (
  data: Partial<CreateTwistPayload>[]
) => {
  return callAPI<CreateRecipeTwistsAction>({
    types: [
      actionTypes.CREATE_RECIPE_TWIST_REQUEST,
      actionTypes.CREATE_RECIPE_TWIST_SUCCESS,
      actionTypes.CREATE_RECIPE_TWIST_FAILURE,
    ],
    endpoint: API.createTwist,
    method: "POST",
    body: humps.decamelizeKeys(data),
  });
};
