import { Action } from 'types';
import { AuthState } from 'types/auth';
import actionTypes from 'redux/actionTypes';

export const authReducer = (
  state: AuthState = {
      isAuthenticated: true,
  },
  action: Action
): AuthState => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN_SUCCESS:
    case actionTypes.VERIFY_TOKEN_SUCCESS:
      return { ...state, isAuthenticated: true };
    case actionTypes.FETCH_TOKEN_FAILURE:
    case actionTypes.VERIFY_TOKEN_FAILURE:
      return { ...state, isAuthenticated: false };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      }
    default:
      return state;
  }
};
