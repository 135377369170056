import React from 'react';
import { Box, Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';

import { useForm } from 'hooks';

import LabelSelect from 'components/LabelSelect';
import Prompt from 'components/Prompt';

import {
  MAX_PACK_THEMES_PER_PACK,
} from 'constants/data';
import { BasePack, Pack } from 'types';

export interface FormValues {
  [key: string]: string;
}

interface Props {
  pack: Pack | BasePack;
  themes: string[];
  onSubmit: (values: FormValues) => Promise<void>;
  isLoading?: boolean;
  isSubmitting?: boolean;
}

const Tag = ({ tag }: { tag: string }) => (
  <Heading
    as="h3"
    size="md"
    padding="xs"
    backgroundColor="blue.500"
    textAlign="center"
    color="white"
    borderRadius="3"
  >
    {tag}
  </Heading>
);

const PackAdditionalDetailsForm: React.FC<Props> = ({
  pack,
  themes,
  onSubmit,
  isLoading = false,
  isSubmitting = false,
}) => {
  const { register, handleSubmit, formState, reset } = useForm<FormValues>();

  const { isDirty } = formState;

  const onSubmitData = handleSubmit(async data => {
    await onSubmit(data);
    reset(data);
  });

  return (
    <>
      <Prompt when={isDirty} message="You have unsaved Changes. Are you sure you want to leave?" />
      <form onSubmit={onSubmitData}>
        <Heading as="h3" size="lg" mb="sm">
          Additional Information
        </Heading>
        <Flex flexDir="column">
          <Box w={['100%', '100%', 560]}>
            <Text mt="5" mb="3" fontWeight="bold">
              Pack Themes
            </Text>
            {'themes' in pack && (
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {Array.from(Array(MAX_PACK_THEMES_PER_PACK).keys()).map(idx => {
                  return (
                    <LabelSelect
                      key={`theme-tag-${idx}`}
                      name={`theme-tag-${idx}`}
                      register={register}
                      placeholder="Select Theme"
                      options={themes}
                      defaultValue={pack.themes[idx] || '-'}
                    />
                  );
                })}
              </SimpleGrid>
            )}
            <Text fontWeight="bold" mb="3" mt="5">
              Dietary Tag (calculated)
            </Text>
            <Box>{'dietaryTag' in pack && <Tag tag={pack.dietaryTag} />}</Box>
            <Text fontWeight="bold" mb="3" mt="5">
              Nutritional Tags (calculated)
            </Text>
            {'nutritionalTags' in pack && (
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {pack.nutritionalTags.length ? (
                  pack.nutritionalTags?.map(tag => {
                    return <Tag key={`nutritional-tag-${tag}`} tag={tag} />;
                  })
                ) : (
                  <Text>No nutritional tags</Text>
                )}
              </SimpleGrid>
            )}
            <Text fontWeight="bold" mb="3" mt="5">
              Seasonal Tags (calculated)
            </Text>
            {'seasonalTags' in pack && (
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {pack.seasonalTags.length ? (
                  pack.seasonalTags?.map(tag => {
                    return <Tag key={`seasonal-tag-${tag}`} tag={tag} />;
                  })
                ) : (
                  <Text>No seasonal tags</Text>
                )}
              </SimpleGrid>
            )}
            <Text mt="5" mb="3" fontWeight="bold">
              Attributes (calculated)
            </Text>
            {'attributeTags' in pack && (
              <SimpleGrid mt="xs" columns={4} spacing="sm">
                {pack.attributeTags.length ? (
                  pack.attributeTags?.map(tag => {
                    return <Tag key={`attribute-tag-${tag}`} tag={tag} />;
                  })
                ) : (
                  <Text>No attributes</Text>
                )}
              </SimpleGrid>
            )}
          </Box>
        </Flex>
        <Flex flex={1} alignItems="flex-end" justifyContent="flex-end" paddingTop="md">
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isLoading || !isDirty}
          >
            Save
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default PackAdditionalDetailsForm;
