import {
  AltImages,
  Rating,
  Collaborator,
  DietaryTag,
  NutritionalTag,
  Twist,
  Unit,
  Status,
} from 'types';

export type RecipeType = 'default' | 'youtube' | 'standalone';
export interface CookingTime {
  id?: number;
  numPeople?: number;
  duration?: number;
  recipe?: number;
}

type PortionType = 'primary' | 'secondary' | 'any';

// User Facing RecipeBase
export interface UFRecipeBase {
  altImages: {};
  code: string;
  cookingTime: CookingTime[];
  equipmentItems: number[];
  finishedImporting: boolean;
  id: number;
  image: string | null;
  index: number;
  introAudio: string | null;
  isPreviousData: boolean;
  numPeople: string;
  pack: number;
  title: string;
  twists: [];
}

export interface Recipe {
  id: number;
  method: number | null;
  status: Status | null;
  index: number;
  code: string;
  title: string;
  image: string | null;
  introAudio: string | null;
  numPeople: string;
  finishedImporting: boolean;
  isPreviousData: boolean;
  pack: number | null;
  packCode: string | null | undefined; // Write Only
  cookingTime: CookingTime[];
  equipmentItems: number[];
  recipeequipmentSet: {
    equipment: number;
    id?: number;
    portion: PortionType;
    quantity: number;
    recipe: number;
    group?: string;
  }[];
  story: string;
  averageRating: number | null;
  twists: Twist[];
  portionSize?: string;
  recipeType: RecipeType;
  attributeTags: string[];
  budget: boolean;
  cuisineTags: string[];
  dietaryTag: DietaryTag;
  nutritionalTags: NutritionalTag[];
  seasonalTags: string[];
  freshIngredientCost: string;
  storeCupboardIngredientCost: string;
}

export interface RecipeListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Recipe[];
}

export interface UnitConversion {
  metricQuantity: number;
  metricUnit: string;
  imperialQuantity: number;
  imperialUnit: string;
}
export interface Quantity {
  id: number | null;
  prepInstructions?: string | null;
  recipeIngredient?: number;
  quantity: number;
  unit: Unit;
}

export interface Timer {
  id: number;
  title: string;
  instruction: string;
  duration: number;
  endAudio: string | null;
  index: string;
  recipeCode: string | null;
}

export interface MethodStepTask {
  id: number;
  timer: Timer | null;
  taskNumber: number;
  method: string;
  instructions: string;
  numPeople: number;
  audio: string | null;
  step: number;
  equipment: number;
  // DEPRECATED
  isTimerEndTask?: boolean;
}

export interface MethodStep {
  id: number;
  tasks: MethodStepTask[];
  stepNumber: number;
  title: string;
  headerPhoto: string | null;
  headerAudio: string | null;
  numPeople: number;
  method: number;
  equipment: number | null;
}

export interface FormTimer {
  id: number | undefined;
  duration: number;
  instruction: string | null;
  title: string | null;
  endAudio: string | null | undefined;
}
export interface FormStepTask {
  id: number | undefined;
  method: string;
  instructions: string;
  audio?: string | null;
  timer?: FormTimer | null;
  taskNumber: number;
  // DEPRECATED
  numPeople?: number;
  equipment?: number | undefined;
}
export interface FormMethodStep {
  id?: number | null;
  title: string | null;
  headerPhoto?: string | null;
  stepNumber: number;
  numPeople: number;
  tasks: FormStepTask[];
}

/**
 * The fields of a MethodStep which can be updated over the API
 */
export type UpdateableMethodStep = {
  id?: number | null;
  title: string | null;
  stepNumber: number;
  numPeople?: number;
  tasks: {
    id: number | undefined;
    method: string;
    timer?: {
      id: number | undefined;
      duration: number;
      instruction: string | null;
    } | null;
    taskNumber: number;
  }[];
};

export interface RecipeSummary {
  attributeTags: string[];
  budget: boolean;
  code: string;
  collaborator: Collaborator | null;
  cookingTime: CookingTime[];
  cuisineTags: string[];
  dietaryTag: DietaryTag;
  id: number;
  uid: string;
  image: string | null;
  imageBlurHash: string | null;
  ingredientCount: number;
  nutritionalTags: NutritionalTag[];
  pack: number | null;
  packTitle: string | null;
  recipeEquipment: number[];
  recipeIngredients: number[];
  resizedImages: AltImages;
  resizedFeaturedImages: AltImages;
  recipeType: RecipeType;
  relatedRecipes: { id: number; uid: string }[];
  seasonalTags: string[];
  story: string;
  title: string;
  rating: Rating;
}

export type RecipeEquipment = {
  equipment: number;
  id: number;
  portion: 'any' | 'primary' | 'secondary';
  quantity: number;
  recipe: number;
};

export type IngredientQuantity = {
  id: number;
  prepInstructions: string | null;
  quantity: number;
  recipeIngredient: number;
  unit: Unit;
};

export type RecipeIngredient = {
  group: string | null;
  id: number;
  index: number;
  ingredient: number | null;
  quantities: IngredientQuantity[];
  recipe: number;
  disableScaling: boolean;
};

export enum IngredientTypes {
  alcohol = 'Alcohol',
  bread = 'Bread',
  carbsGrainsSugars = 'Staple Carbs / Grains / Sugars',
  dairyEggs = 'Dairy / Eggs',
  equipment = 'Equipment',
  frozen = 'Frozen',
  fruit = 'Fruit / Veg / Herbs',
  meatFishShellfish = 'Meat / Fish / Shellfish',
  nonFood = 'Non-food',
  nutsSeedsDriedFruits = 'Nuts / Seeds / Dried Fruit',
  seasonings = 'Seasonings',
  seasoningsSpices = 'Seasonings / Spices',
  staple = 'Staple Carbs / Grains',
  tinsBottlesJars = 'Tins / Bottles / Jars',
  tinsJarsBottles = 'Tins / Jars / Bottles',
}
