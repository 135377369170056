import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Input,
  Button,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { fetchToken } from 'redux/actions/auth';
import { useDispatch } from 'hooks';

import routes from 'navigation/routes';

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit = async () => {
    if (email && password) {
      setIsLoading(true);
      const response = await dispatch(
        fetchToken({
          email,
          password,
        }),
      );

      if (response.error) {
        const { payload } = response;

        if (payload && 'nonFieldErrors' in payload) {
          setLoginError(payload.nonFieldErrors[0]);
        } else {
          setLoginError('The username/email address or password you entered is incorrect.');
        }

        setIsLoading(false);
      } else {
        // TODO: Replace with generic welcome screen
        navigate(routes.packList);
      }
    }
  };

  return (
    <Flex padding="md" justifyContent="center">
      <Flex
        flexDir="column"
        maxWidth={550}
        width={['100%', '70%', '50%']}
        boxShadow="lg"
        borderRadius="lg"
        padding={5}
        overflow="hidden"
        flexShrink={0}
      >
        <Heading textAlign="center" my="sm">
          Sorted Packs - CMS
        </Heading>
        <FormControl id="email" isRequired isInvalid={Boolean(loginError)}>
          <FormLabel>Email address</FormLabel>
          <Input
            placeholder="admin@sorted.club"
            type="email"
            marginBottom={10}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </FormControl>
        <FormControl id="password" isRequired isInvalid={Boolean(loginError)}>
          <FormLabel>Password</FormLabel>
          <Input
            placeholder="******"
            type="password"
            onChange={({ target: { value } }) => setPassword(value)}
            onKeyDown={e => {
              if (e.key === 'Enter') onSubmit();
            }}
          />
          <FormErrorMessage>{loginError}</FormErrorMessage>
        </FormControl>
        <Flex marginTop="sm">
          <Button isLoading={isLoading} onClick={onSubmit} isDisabled={!email || !password}>
            Login
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginContainer;
