import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  InputProps,
  HStack,
} from '@chakra-ui/react';
import { UseFormRegister, RegisterOptions, FieldPath, FieldValues } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>
  extends InputProps {
  name: TFieldName;
  register?: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions<TFieldValues, TFieldName>;
  label?: string;
  helpText?: string;
  errorText?: string;
  icon?: any;
}

function LabelInput<
  TFieldValues extends Record<string, any>,
  TFieldName extends FieldPath<TFieldValues>,
>({
  id,
  name,
  type,
  register,
  registerOptions = {},
  label,
  helpText,
  errorText,
  placeholder,
  defaultValue,
  icon,
  isInvalid = false,
  isDisabled = false,
  isRequired = false,
  size = 'md',
  ...rest
}: Props<TFieldValues, TFieldName>) {
  return (
    <FormControl id={id} isDisabled={isDisabled} isInvalid={isInvalid} isRequired={isRequired}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <HStack>
        <Input
          {...(register ? register(name, registerOptions) : {})}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          size={size}
          bgColor="white"
          {...rest}
        />
        {icon ? icon : null}
      </HStack>
      {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
      {errorText ? <FormErrorMessage>{errorText}</FormErrorMessage> : null}
    </FormControl>
  );
}

export default LabelInput;
