import { RSAA, getJSON, RSAAAction } from "redux-api-middleware";
import { camelizeKeys } from "humps";
import get from "lodash/get";
import { normalize, Schema } from "normalizr";

import { APIErrorResponse, ThunkAction } from "types";
import { getCookie } from "utils/index";

export interface MetaType {
  [key: string]: any;
  // Define whether to show a 404 page for the given pathname
  // in case this requests results in a 404 error
  pathname404?: string;
}
export interface CallAPI {
  endpoint: string;
  method?: string;
  types: string[];
  body?: Object;
  headers?: { [key: string]: string };
  credentials?: "omit" | "same-origin" | "include";
  // Optional schema: used as the basis for normalization
  schema?: Schema;
  // Optional dot notation path of data to normalize: If provided,
  // will be used to set which data to normalize within our JSON.
  // By default, the JSON returned from the API is normalized at
  // its root. If a path is supplied, e.g. 'results' then 'json.results'
  // will be normalized
  path?: string;
  bailout?: Function | boolean;
  meta?: MetaType;
  fetchNextPage?: ((nextUrl: string) => Promise<any>) | null | undefined;
}

/**
 * Helper function to sit on top of `redux-api-middleware` and provide us
 * with a simple call signature when interacting with the API.
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'GET',
 *  types: [
 *    'GET_TEST_REQUEST',
 *    'GET_TEST_SUCCESS',
 *    'GET_TEST_FAILURE',
 *  ],
 * })
 * // => ThunkAction
 *
 *
 * callAPI({
 *  endpoint: 'test/',
 *  method: 'POST',
 *  types: [
 *    'POST_TEST_REQUEST',
 *    'POST_TEST_SUCCESS',
 *    'POST_TEST_FAILURE',
 *  ],
 *  body: decamelizeKeys(body),
 * })
 * // => ThunkAction
 *
 */
function callAPI<T>({
  endpoint,
  method = "GET",
  types,
  body,
  headers,
  credentials = "include",
  schema,
  path,
  bailout,
  meta,
  fetchNextPage,
}: CallAPI): ThunkAction<T> {
  // Make our actionTypes for redux-api-middleware
  const makeTypes = types
    .map((type) => {
      // If we have a request body, add it to the request
      if (type.includes("REQUEST")) {
        return {
          type,
          ...(body ? { payload: body } : {}),
          ...(meta ? { meta: { ...meta, toast: {} } } : {}),
        };
      }

      // If we have a schema, we want to normalize.
      if (type.includes("SUCCESS")) {
        return {
          type,
          payload: (action: RSAAAction, state: any, res: Response) =>
            getJSON(res)
              .then(async (res) => {
                // Fetch the next page if api is paginated and has a next page url
                res &&
                  res.next &&
                  fetchNextPage &&
                  // TODO: DRF returns 'next' url with incorrect protocol
                  (await fetchNextPage(res.next.replace("http", "https")));
                return res;
              })
              .then(camelizeKeys)
              // If a custom normalize function has been provided, use that, otherwise
              // default to using the supplied schema to normalize the JSON at the
              // top level.
              .then((json) => {
                return schema
                  ? normalize(path ? get(json, path) : json, schema)
                  : json;
              }),
          ...{ meta: { ...meta } },
        };
      }

      if (type.includes("FAILURE")) {
        return {
          type,
          payload: (action: RSAAAction, state: any, res: Response) =>
            getJSON(res).then((res: APIErrorResponse) => {
              if (res.code) {
                return { ...res, code: res.code };
              }
              return camelizeKeys(res);
            }),
          ...{ meta: { ...meta } },
        };
      }

      return null;
    })
    .filter((a) => a);

  const csrfToken = getCookie('csrftoken');

  const finalHeaders = {
    'Content-Type': 'application/json',
    ...headers,
    ...(csrfToken ? { 'X-CSRFToken': getCookie('csrftoken') } : {}),
  };

  // For 'Content-Type':'multipart/form-data', the Browser need to calculate the Boundary & set the content-type itself
  // Also we do not want to stringify it as it is a FormData type
  const isFormData = body instanceof FormData;
  // @ts-ignore
  if (isFormData) delete finalHeaders["Content-Type"];

  return (dispatch, getState) =>
    // @ts-ignore
    dispatch({
      //@ts-ignore
      [RSAA]: {
        endpoint,
        method,
        headers: {
          ...finalHeaders,
        },
        types: makeTypes,
        credentials,
        ...(body ? { body: isFormData ? body : JSON.stringify(body) } : {}),
        bailout: bailout || false,
      },
    });
}

export default callAPI;
