import { useEffect } from "react";

const useTitle = (title?: string) => {
  useEffect(() => {
    if (title) document.title = title;

    return () => {
      document.title = "Sorted Packs - CMS";
    };
  }, [title]);
};

export default useTitle;
