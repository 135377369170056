/**
 * Takes an array of objects and returns an object
 * with custom keys e.g.
 * [string, string] => { [key: string]: string, [key: string]: string }
 */

const reduceArr = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T
): Record<keyof T, T> =>
  Object.assign(
    {} as { [key: string]: T },
    ...arr.map((a) => ({
      [a[key]]: a,
    }))
  );

export default reduceArr;
