import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import store from 'redux/store';

import AppRouter from 'navigation/AppRouter';

import { Text } from '@chakra-ui/react';
import { theme } from 'theme/chakra';

// Initialise Sentry SDK if in production
if (process.env.NODE_ENV === 'production') {
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  const tracesSampleRate: number =
    process.env.REACT_APP_SENTRY_SAMPLE_RATE &&
    typeof process.env.REACT_APP_SENTRY_SAMPLE_RATE === 'number'
      ? process.env.REACT_APP_SENTRY_SAMPLE_RATE
      : 1.0;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,

    normalizeDepth: 5,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={() => <Text>An error has occurred</Text>} showDialog>
            <AppRouter />
          </Sentry.ErrorBoundary>
        </ChakraProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
