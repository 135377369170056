import React from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';

function Prompt({ when, message }: PromptProps) {
  // usePrompt({ when: when, message });
  return null;
}

interface PromptProps {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
}

export default Prompt;
